// React Basic and Bootstrap
import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Col,

  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//Import Components
import Feature from "../../components/Shared/Feature";
import { serviceFeatures } from "../../common/data";

const Features = () => {
  return (
    <React.Fragment>
      <section className="section pt-0">
        <Container>
          {/* feature box */}
          {/* <Feature featureArray={serviceFeatures} isCenter={true} /> */}
          <Row>
              <Col md={4} className="mt-4 pt-2">
                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                  <NavItem>
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Data Engineering</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "2" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Security & Reliability</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "3" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">AI|Machine Learning |NLP</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "4" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Research & Development</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mt-2">
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "5" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("5");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Cloud Computing</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mt-2">
                    <NavLink
                      to="/page-services"
                      className={classnames(
                        { active: this.state.activeTab === "6" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("6");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Communication & Availability</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                </ul>
              </Col>

              <Col md={8} xs={12} className="mt-4 pt-2">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="1"
                  >
                    <img
                      src={work1}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      Data is new oil. Industry generates lot of data but dont know how to use it to gain insights. We mine the data and put insights in front of you
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white p-4 show rounded shadow"
                    tabId="2"
                  >
                    <img
                      src={work2}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      We help enhance the security of your current system and ensure the services are always available for your customers.
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white p-4  show rounded shadow"
                    tabId="3"
                  >
                    <img
                      src={work3}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      We help you to identify likelihood of future outcomes using data, statistics, and machine learning techniques.
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="4"
                  >
                    <img
                      src={work4}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      We assist you in all the emerging technologies with our inhouse experts and researchers.
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="5"
                  >
                    <img
                      src={work4}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      We use cloud services to enable fast development, create robust and scalable solutions for our customers.
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="6"
                  >
                    <img
                      src={work3}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                      Our team is always available and responsive to clients request. This ensures to keep high quality services delivery to our clients.
                      </p>
                      <Link to="/page-services" className="text-primary">
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
