// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import Lightbox from 'react-image-lightbox';
import '../../../../../node_modules/react-image-lightbox/style.css';

//Import Images
import work1 from "../../../../assets/images/work/20.jpg";
import work2 from "../../../../assets/images/work/13.jpg";
import work3 from "../../../../assets/images/work/14.jpg";
import work4 from "../../../../assets/images/work/15.jpg";
import work5 from "../../../../assets/images/work/16.jpg";
import work6 from "../../../../assets/images/work/17.jpg";
import work7 from "../../../../assets/images/work/18.jpg";
import work8 from "../../../../assets/images/work/19.jpg";

//creating array of images for image portfolio
const images = [
    work1, work2, work3, work4, work5, work6, work7, work8
];

class Work extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [
                { image: work1, title: "Jatana AI", subtitle: "AI Automations for better Customer Experiences.", category: "Branding" ,link:'/portfolio-detail-one'},
                { image: work2, title: "Realtime Air Quality Monitoring", subtitle: "India’s first affordable PM2.5 monitor with free data and dashboard", category: "Designing",link:'/portfolio-detail-two' },
                { image: work3, title: "Video Calling Customer Service (HIPAA)", subtitle: "Bring customer and businesses closed during a pandemic", category: "Photography",link:'/portfolio-detail-one' },
                { image: work4, title: "Data Extraction and Analytics at SCALE", subtitle: "Bringing e-commerce data visibility to businesses.", category: "Development",link:'/portfolio-detail-one' },
                { image: work5, title: "Tablepop Event Planner Platform", subtitle: "An event rental platform changing the way people design their entertainment experiences.", category: "Branding",link:'/portfolio-detail-one' },
                { image: work6, title: "Greviance Redressal System", subtitle: "Our solutions Assisting AI company based in London", category: "Branding",link:'/portfolio-detail-one' },
                { image: work7, title: "Cloud Call Center", subtitle: "Call center community for companies and freelancers", category: "Designing",link:'/portfolio-detail-one' },
                // { image: work8, title: "Pen and article", subtitle: "Article", category: "Development" },
            ],
            displayCategory: "All",
            photoIndex: 0,
            isOpen: false,
        };
        this.setCategory.bind(this);
    }

    setCategory(category) {
        this.setState({
            displayCategory: category
        });
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <React.Fragment>
                <section className="section">
                   

                    <Container fluid>
                        <Row id="grid" className="mt-4 ">
                            {this.state.projects
                                .filter(
                                    ({ category }) =>
                                        this.state.displayCategory === category || this.state.displayCategory === "All"
                                )
                                .map(({ title, image, subtitle,link }, key) => (
                                    <Col lg={4} md={6} xs={12} key={key} className="spacing picture-item ">
                                        <Card className="border-0 work-container work-primary work-grid position-relative d-block overflow-hidden rounded">
                                            <CardBody className="p-0">
                                                <Link to="#" className="lightbox d-inline-block" onClick={(event) => { event.preventDefault(); this.setState({ isOpen: true, photoIndex: key }); }} >
                                                    <img src={image} className="img-fluid" alt="work" />
                                                </Link>
                                                <div className="content p-3">
                                                    <h5 className="mb-0"><Link to={link} className="text-dark title">{title}</Link></h5>
                                                    <h6 className="text-muted tag mb-0">{subtitle}</h6>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                ))}

                        </Row>
                        {/* lightbox for portfolio images */}
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                imagePadding={100}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                            />
                        )}
                    </Container>
                    <Container className="mt-100 mt-60">
                        <Row className="justify-content-center">
                            <Col xs="12" className="text-center">
                                <div className="section-title">
                                    <h4 className="title mb-4">Let's talk about your portfolio</h4>
                                    <p className="text-muted para-desc mx-auto">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                    <div className="mt-4 pt-2">
                                        <Link to="#" className="btn btn-primary">Get Started Now</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Work;