// React Basic and Bootstrap
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CountUp from "react-countup";
import classnames from "classnames";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import WorkProcess from "../../components/Shared/WorkProcess";
import work1 from "../../assets/images/experiments/manthan_ai.png";
import work2 from "../../assets/images/experiments/image_sort.png";
import work3 from "../../assets/images/experiments/clustify_ai.png";
import work4 from "../../assets/images/experiments/compli_check.png";
import work5 from "../../assets/images/mywork/Predictive1.png";
import work6 from "../../assets/images/mywork/LLM1.png";
import airBills from "../../assets/images/our client/airbills.png";
import billtrim from "../../assets/images/our client/billtrim.png";
import botsupply from "../../assets/images/our client/botsupply.png";
import ekhoo from "../../assets/images/our client/ekhoo.png";
import jatana from "../../assets/images/our client/jatana.png";
import neuralspace from "../../assets/images/our client/neuralspace.png";
import viralget from "../../assets/images/our client/viralGet.png";
import moxci from "../../assets/images/our client/moxci.png";
import ExperienceTeam from "../CorporateBusiness/ExperienceTeam";
import researchData from "./data.json";

//Import Images

// class Technology extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeTab: "1",
//       partners: [
//         {
//           id: 1,
//           img: botsupply,
//         },
//         {
//           id: 2,
//           img: moxci,
//         },
//         {
//           id: 3,
//           img: billtrim,
//         },
//         {
//           id: 4,
//           img: jatana,
//         },
//         {
//           id: 5,
//           img: ekhoo,
//         },

//         {
//           id: 8,
//           img: airBills,
//         },
//       ],
//     };
//     this.toggle = this.toggle.bind(this);
//   }

//   toggle(tab) {
//     this.setState({ activeTab: tab });
//   }

//   componentDidMount() {
//     document.body.classList = "";
//     document.querySelectorAll("#buyButton").forEach((navLink) => {
//       navLink.classList.add("btn-light");
//       navLink.classList.remove("btn-soft-primary");
//       document.getElementById("top-menu")?.classList.add("nav-light");
//     });

//     window.addEventListener("scroll", this.scrollNavigation, true);
//   }

//   componentWillUnmount() {
//     window.removeEventListener("scroll", this.scrollNavigation, true);
//   }

//   scrollNavigation() {
//     var doc = document.documentElement;
//     var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
//     if (top > 80) {
//       document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
//       document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
//       document.getElementById("topnav")?.classList.add("nav-sticky");
//     } else {
//       document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
//       document.querySelector(".shoppingbtn")?.classList.add("btn-light");
//       document.getElementById("topnav")?.classList.remove("nav-sticky");
//     }
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <section className="section ">
//           <Container className="">
//             <Row className="align-items-end mb-4 pb-4">
//               <SectionTitle
//                 title="Research & Experiments"
//                 desc="Exploring the frontier of technology to innovate and solve
//                     tomorrow's challenges today. Our continuous research and
//                     experiments drive cutting-edge solutions for your success."
//               />
//             </Row>
//             <Row>
//               <Col md={4} className="mt-4 pt-2">
//                 <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
//                   <NavItem>
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "1" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("1");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">Optimized Vector DB</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>

//                   <NavItem className="mt-2">
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "2" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("2");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">AI Animations</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>

//                   <NavItem className="mt-2">
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "3" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("3");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">IOT Temporal DB</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>

//                   <NavItem className="mt-2">
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "4" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("4");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">AI workflow automation</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>
//                   <NavItem className="mt-2">
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "5" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("5");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">Predictive Analysis</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>
//                   <NavItem className="mt-2">
//                     <NavLink
//                       to="#"
//                       className={classnames(
//                         { active: this.state.activeTab === "6" },
//                         "rounded",
//                       )}
//                       onClick={() => {
//                         this.toggle("6");
//                       }}
//                     >
//                       <div className="text-center py-1">
//                         <h6 className="mb-0">LLM chaining</h6>
//                       </div>
//                     </NavLink>
//                   </NavItem>
//                 </ul>
//               </Col>

//               <Col md={8} xs={12} className="mt-4 pt-2">
//                 <TabContent activeTab={this.state.activeTab}>
//                   <TabPane
//                     className="fade bg-white show p-4 rounded shadow"
//                     tabId="1"
//                   >
//                     <img
//                       src={work1}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>

//                   <TabPane
//                     className="fade bg-white p-4 show rounded shadow"
//                     tabId="2"
//                   >
//                     <img
//                       src={work2}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>

//                   <TabPane
//                     className="fade bg-white p-4  show rounded shadow"
//                     tabId="3"
//                   >
//                     <img
//                       src={work3}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>

//                   <TabPane
//                     className="fade bg-white show p-4 rounded shadow"
//                     tabId="4"
//                   >
//                     <img
//                       src={work4}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>
//                   <TabPane
//                     className="fade bg-white show p-4 rounded shadow"
//                     tabId="5"
//                   >
//                     <img
//                       src={work5}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>
//                   <TabPane
//                     className="fade bg-white show p-4 rounded shadow"
//                     tabId="6"
//                   >
//                     <img
//                       src={work6}
//                       className="img-fluid rounded shadow"
//                       alt=""
//                     />
//                     <div className="mt-4">
//                       <p className="text-muted">
//                         This is required when, for example, the final text is
//                         not yet available. Dummy text is also known as
//                         &apos;fill text&apos;. It is said that song composers of
//                         the past used dummy texts as lyrics.
//                       </p>
//                       <Link
//                         to="/portfolio-detail-four"
//                         className="text-primary"
//                       >
//                         See More{" "}
//                         <i className="uil uil-angle-right-b align-middle"></i>
//                       </Link>
//                     </div>
//                   </TabPane>
//                 </TabContent>
//               </Col>
//             </Row>
//           </Container>
//         </section>
//       </React.Fragment>
//     );
//   }
// }

// export default Technology;

class Technology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      researchExperiments: [
        {
          id: "1",
          title: "ImageSort AI",
          description:
            "Because life’s too short to be sorting images manually – let the robots do it!",
          image: work2,
          link: "/research",
        },
        {
          id: "2",
          title: "Manthan AI",
          description:
            "A revolutionary service that transforms the way you interact with GitHub repositories.",
          image: work1,
          link: "/research",
        },
        {
          id: "3",
          title: "Clustify AI",
          description:
            "Analyze textual data in real time by unique clustering algorithm. By focusing on semantic similarity, Clustify AI ensures that your information is organized into distinct, meaningful clusters, eliminating redundancy and overlap. ",
          image: work3,
          link: "/research",
        },
        {
          id: "4",
          title: "CompliCheck",
          description:
            "A groundbreaking service that ensures your company's policy documents align with government guidelines.",
          image: work4,
          link: "/research",
        },
        // {
        //   id: "5",
        //   title: "Predictive Analysis",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work5,
        //   link: "/research",
        // },
        // {
        //   id: "6",
        //   title: "LLM chaining",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work6,
        //   link: "/research",
        // },
      ],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { activeTab, researchExperiments } = this.state;

    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="align-items-end mb-4 pb-4">
              <SectionTitle
                title="Research & Experiments"
                desc="Exploring the frontier of technology to innovate and solve tomorrow's challenges today. Our continuous research and experiments drive cutting-edge solutions for your success."
              />
            </Row>
            <Row>
              <Col md={4} className="mt-4 pt-2">
                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                  {researchExperiments.map((experiment) => (
                    <NavItem key={experiment.id} className="mt-2">
                      <NavLink
                        to="#"
                        className={classnames(
                          { active: activeTab === experiment.id },
                          "rounded",
                        )}
                        onClick={() => this.toggle(experiment.id)}
                      >
                        <div className="text-center py-1">
                          <h6 className="mb-0">{experiment.title}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                  ))}
                </ul>
              </Col>

              <Col md={8} xs={12} className="mt-4 pt-2">
                <TabContent activeTab={activeTab}>
                  {researchExperiments.map((experiment) => (
                    <TabPane
                      key={experiment.id}
                      className="fade bg-white show p-4 rounded shadow"
                      tabId={experiment.id}
                    >
                      <img
                        src={experiment.image}
                        className="img-fluid rounded shadow"
                        alt=""
                      />
                      <div className="mt-4">
                        <p className="text-muted">{experiment.description}</p>
                        <Link to={experiment.link} className="text-primary">
                          See More{" "}
                          <i className="uil uil-angle-right-b align-middle"></i>
                        </Link>
                      </div>
                    </TabPane>
                  ))}
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Technology;
