// React Basic and Bootstrap
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Button,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import { db } from "../../../firebase";
//Import Icons
import FeatherIcon from "feather-icons-react";
import ThemeSwitcher from "../../../components/Layout/ThemeSwitcher";
// import images
import contactDetail from "../../../assets/images/contact-detail.jpg";
import Dot from "../../../assets/images/work/Grid1.png";
import Topbar from "../../../components/Layout/Topbar";

const PageContactDetail = () => {
  const [user, setUser] = useState({
    name: "",
    subject: "",
    comments: "",
    email: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
  });

  const [massage, setMassage] = useState(false);
  // constructor(props) {
  //   super(props);
  //   this.sendMail.bind(this);
  //   this.callNumber.bind(this);
  // }

  // componentDidMount() {
  //   document.body.classList = "";
  //   document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
  //   window.addEventListener("scroll", this.scrollNavigation, true);
  // }

  // Make sure to remove the DOM listener when the component is unmounted.
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.scrollNavigation, true);
  // }

  // scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   const navBar = document.getElementById("topnav");
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (navBar != null) {
  //     if (top > 80) {
  //       navBar.classList.add("nav-sticky");
  //     } else {
  //       navBar.classList.remove("nav-sticky");
  //     }
  //   }
  // };

  // sendMail() {
  //   window.location.href = "mailto:contact@example.com";
  // }

  // callNumber() {
  //   window.location.href = "tel:+152534-468-854";
  // }

  const handleInputValue = (e) => {
    setUser({
      ...user,
      name: e.target.value,
    });
  };
  const handleEmailValue = (e) => {
    setUser({
      ...user,
      email: e.target.value,
    });
  };
  const handleComments = (e) => {
    setUser({
      ...user,
      comments: e.target.value,
    });
  };
  const handleSubject = (e) => {
    setUser({
      ...user,
      subject: e.target.value,
    });
  };
  const sendEmail = async (e) => {
    e.preventDefault();
    // Reset error messages at the beginning of the function
    setError({ name: "", email: "" });

    // Check if name or email is empty and set error message
    if (!user.name) {
      setError((prevError) => ({ ...prevError, name: "Name is required." }));
    }
    if (!user.email) {
      setError((prevError) => ({ ...prevError, email: "Email is required." }));
    }

    // If name or email is empty, stop the function execution
    if (!user.name || !user.email) {
      return;
    }

    console.log("inside sendEmail", user);
    try {
      db.collection("iaastha-website")
        .add({ user: user })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          localStorage.setItem("docId", docRef.id);
          // Clear user state after successful email send
          setMassage(true);
          setUser({
            name: "",
            subject: "",
            comments: "",
            email: "",
          });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <React.Fragment>
      <Topbar hasDarkTopBar={true} />

      <section
        className="vh-100 d-flex align-items-center"
        style={{
          background: `url(${Dot}) center center`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-overlay bg-overlay-white"></div>
        <Container>
          <Row className="align-items-center">
            <Col
              lg={5}
              md={6}
              className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
            >
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  {massage && (
                    <UncontrolledAlert
                      color="success"
                      className="alert-dismissible fade show"
                    >
                      <strong>Thanks You! </strong>
                      <br />
                      your details send successfully!
                    </UncontrolledAlert>
                  )}
                  <h4 className="card-title">Get In Touch !</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    {/* <Alert
                      color="primary"
                      isOpen={this.state.Contactvisible}
                      toggle={() => {
                        this.setState({
                          Contactvisible: !this.state.Contactvisible,
                        });
                      }}
                    >
                      Contact details send successfully.
                    </Alert> */}
                    <Form
                      method="post"
                      // onSubmit={this.handleSubmit}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Your name :"
                              onChange={handleInputValue}
                              value={user.name}
                            />
                            {error.name && (
                              <p style={{ color: "red" }}>{error.name}</p>
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control ps-5"
                              placeholder="Your email :"
                              onChange={handleEmailValue}
                              value={user.email}
                            />
                            {error.name && (
                              <p style={{ color: "red" }}>{error.name}</p>
                            )}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Subject</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="book"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="subject"
                              id="subject"
                              className="form-control ps-5"
                              placeholder="Your subject :"
                              onChange={handleSubject}
                              // required
                              value={user.subject}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Comments</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control ps-5"
                              placeholder="Your message :"
                              onChange={handleComments}
                              value={user.comments}
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              onClick={sendEmail}
                              value="Send Message"
                            />
                          </div>
                          <div id="simple-msg"></div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6}>
              <div className="title-heading mt-5 pt-4">
                <h1 className="heading">Let's talk about your portfolio</h1>
                <p className="text-dark">
                  Start working with{" "}
                  <span className="text-primary fw-bold">IAastha</span> that can
                  provide everything you need to generate awareness, drive
                  traffic, connect.
                </p>

                {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <FeatherIcon
                        icon="mail"
                        className="fea icon-m-md text-dark me-3"
                      />
                    </div>
                    <div className="flex-1 content">
                      <h5 className="title fw-bold mb-0">Email</h5>
                      <Link
                        to="#"
                        onClick={this.sendMail}
                        className="text-primary"
                      >
                        contact@example.com
                      </Link>
                    </div>
                  </div> */}

                {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <FeatherIcon
                        icon="phone"
                        className="fea icon-m-md text-dark me-3"
                      />
                    </div>
                    <div className="flex-1 content">
                      <h5 className="title fw-bold mb-0">Phone</h5>
                      <Link
                        to="#"
                        onClick={this.callNumber}
                        className="text-primary"
                      >
                        +152 534-468-854
                      </Link>
                    </div>
                  </div> */}

                <div className="d-flex contact-detail align-items-start mt-4">
                  <div className="icon">
                    <FeatherIcon
                      icon="map-pin"
                      className="fea icon-m-md text-dark me-3"
                    />
                  </div>
                  <div className="flex-1 content">
                    <h5 className="title fw-bold mb-0">Dubai</h5>
                    <p className="text-muted">
                      MAG 555, Dubai South, Next to Expo 2020 <br />
                      Dubai, UAE
                    </p>
                  </div>
                </div>
                <div className="d-flex contact-detail align-items-start mt-3">
                  <div className="icon">
                    <FeatherIcon
                      icon="map-pin"
                      className="fea icon-m-md text-dark me-3"
                    />
                  </div>
                  <div className="flex-1 content">
                    <h5 className="title fw-bold mb-0">Indore</h5>
                    <p className="text-muted">
                      Floor 2 & 3, PU4, Vijay Nagar <br />
                      Indore, India
                    </p>
                  </div>
                </div>
                <div className="d-flex contact-detail align-items-start mt-3">
                  <div className="icon">
                    <FeatherIcon
                      icon="map-pin"
                      className="fea icon-m-md text-dark me-3"
                    />
                  </div>
                  <div className="flex-1 content">
                    <h5 className="title fw-bold mb-0">Mumbai</h5>
                    <p className="text-muted">
                      Goodwill Infinity, Kharghar <br />
                      Mumbai, India
                    </p>
                  </div>
                </div>

                {/* <ul className="list-unstyled social-icon mb-0 mt-4">
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="facebook"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="instagram"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="twitter"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="linkedin"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="github"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="youtube"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="gitlab"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>
                  </ul> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <ThemeSwitcher/> */}
    </React.Fragment>
  );
};
export default PageContactDetail;
