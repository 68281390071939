import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
// import { withRouter } from "react-router-dom";
import blog5 from "../../../assets/images/blog/travel/e-comm-extraction.png";
import illustrator from "../../../assets/images/illustrator/Asset190.svg";
import illustrator2 from "../../../assets/images/illustrator/Asset189.svg";
import illustrator3 from "../../../assets/images/illustrator/Asset187.svg";
import client1 from "../../../assets/images/client/01.jpg";
import CountUp from "react-countup";
import work2 from "../../../assets/images/experiments/manthan_ai.png";
import work1 from "../../../assets/images/experiments/image_sort.png";
import work3 from "../../../assets/images/experiments/clustify_ai.png";
import work4 from "../../../assets/images/experiments/compli_check.png";
import work5 from "../../../assets/images/mywork/Predictive1.png";
import work6 from "../../../assets/images/mywork/LLM1.png";
import { Link } from "react-router-dom";

export default class ResearchDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      researchExperiments: [
        {
          id: "1",
          title: "ImageSort AI",
          description:
            "Because life’s too short to be sorting images manually – let the robots do it!",
          image: work1,
          link: "/research",
        },
        {
          id: "2",
          title: "Manthan AI",
          description:
            "A revolutionary service that transforms the way you interact with GitHub repositories.",
          image: work2,
          link: "/research",
        },
        {
          id: "3",
          title: "Clustify AI",
          description:
            "Analyze textual data in real time by unique clustering algorithm. By focusing on semantic similarity, Clustify AI ensures that your information is organized into distinct, meaningful clusters, eliminating redundancy and overlap. ",
          image: work3,
          link: "/research",
        },
        {
          id: "4",
          title: "CompliCheck",
          description:
            "A groundbreaking service that ensures your company's policy documents align with government guidelines.",
          image: work4,
          link: "/research",
        },
        // {
        //   id: "5",
        //   title: "Predictive Analysis",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work5,
        //   link: "/research",
        // },
        // {
        //   id: "6",
        //   title: "LLM chaining",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work6,
        //   link: "/research",
        // },
      ],
    };
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    // JSON data defined here
    const blogs = [
      {
        id: "1",
        title: "ImageSort AI",
        description:
          "Because life’s too short to be sorting images manually – let the robots do it!",
        image: work1,
        link: "/research",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "A cutting-edge image categorization service that leverages advanced transfer learning techniques to effortlessly sort and classify large volumes of random images. With just a few sample images per category, ImageSortAI can organize vast image libraries into predefined categories without the need for manual effort. Whether you're managing a personal photo collection or handling a professional image database, ImageSortAI provides an efficient and accurate solution, saving you both time and resources."
          },
          {
            type: "paragraph",
            title: "",
            text: "What sets ImageSortAI apart is its unsupervised learning approach, allowing it to adapt and improve its categorization capabilities without the need for continuous human intervention. Designed to work in real-time applications, ImageSortAI can handle dynamic image inputs and instantly classify them into the appropriate categories. This makes it an ideal solution for applications that require immediate image sorting, such as real-time content moderation, live event photo management, or any scenario where quick and accurate image categorization is essential."
          },
          {
            type: "paragraph",
            title: "",
            text: "Despite its powerful capabilities, ImageSortAI is also resource-efficient, making it accessible and affordable for users with limited computational power. The service is built to scale, ensuring that whether you're dealing with a small image collection or a massive image database, ImageSortAI can handle the workload with ease. By automating the categorization process, ImageSortAI empowers users to focus on more critical tasks, enhancing productivity and simplifying image management for both individuals and organizations."
          },
        ]
      },
      {
        id: "2",
        title: "Manthan AI",
        description:
          "A revolutionary service that transforms the way you interact with GitHub repositories.",
        image: work2,
        link: "/research",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "A revolutionary service that transforms the way you interact with GitHub repositories. Whether you're working with a public or private repo, ManthanAI leverages cutting-edge LLM chaining and powerful LLM models to provide a seamless chat interface. Simply provide a link to your GitHub repo, and ManthanAI will clone, compress, and process it, allowing you to ask detailed questions about the code, structure, or documentation. It’s like having a smart assistant that not only understands your codebase but also gives you instant insights, making code exploration as easy as a conversation."
          },
          {
            type: "paragraph",
            title: "",
            text: "What makes ManthanAI even more powerful is its ability to handle multiple repositories simultaneously. You can provide multiple repo URLs and ask questions that span across different projects, allowing you to connect the dots and gain a holistic understanding of your entire codebase. Additionally, ManthanAI allows you to save sessions, so you can return later and continue your exploration without starting from scratch. This feature is particularly useful for developers who need to revisit complex projects or share insights with team members over time."
          },
          {
            type: "paragraph",
            title: "",
            text: "ManthanAI is designed to significantly reduce the time developers spend on understanding code, as studies show that developers spend more time reading and comprehending code rather than writing it. By providing quick access to detailed information, generating code snippets, and even creating documentation, ManthanAI streamlines the development process. This not only accelerates productivity but also enhances code quality by ensuring that developers fully grasp the structure and logic of their projects before making changes. Empower your development workflow with ManthanAI and experience a new level of efficiency in coding and collaboration."
          },
        ]
      },
      {
        id: "3",
        title: "Clustify AI",
        description:
          "Analyze textual data in real time by unique clustering algorithm. By focusing on semantic similarity, Clustify AI ensures that your information is organized into distinct, meaningful clusters, eliminating redundancy and overlap. ",
        image: work3,
        link: "/research",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "An AI-powered service that redefines how you cluster and analyze textual data in real time. By focusing on semantic similarity, ClustifyAI ensures that your information is organized into distinct, meaningful clusters, eliminating redundancy and overlap. Whether you're managing dynamic content or static datasets, ClustifyAI's cutting-edge technology delivers clarity and precision, empowering you to make informed decisions quickly."
          },
          {
            type: "paragraph",
            title: "",
            text: "At the core of ClustifyAI is its proprietary algorithm combined with a Rotational Vector DB, specifically designed for handling time-sensitive data like news articles or frequently updated content. This innovative approach ensures that your clusters remain relevant, focusing on the latest information rather than outdated data. As new data flows in, ClustifyAI dynamically adjusts the clusters, making it particularly useful for industries that rely on up-to-the-minute information, such as media, finance, or social media analysis."
          },
          {
            type: "paragraph",
            title: "",
            text: "Efficiency is a hallmark of ClustifyAI. By streamlining the clustering process, it not only saves time but also helps you identify emerging trends within your data. As new information enters the system, ClustifyAI continuously updates the clusters, providing you with insights into the latest developments and shifts in your field. Whether you’re tracking breaking news, monitoring market trends, or analyzing social media chatter, ClustifyAI offers unmatched efficiency and insight, ensuring you stay ahead of the curve."
          },
        ]
      },
      {
        id: "4",
        title: "CompliCheck",
        description:
          "A groundbreaking service that ensures your company's policy documents align with government guidelines.",
        image: work4,
        link: "/research",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "A service that ensures your company's policy documents align with government guidelines. CompliCheck takes publicly available government guidelines and your internal policy documents, using advanced transfer learning techniques to compare them. By thoroughly analyzing both documents, it provides a detailed report on how well your policy matches the government’s requirements, highlighting areas of compliance and pinpointing any gaps where guidelines are not covered. It's an essential tool for companies seeking to maintain regulatory compliance and avoid potential legal pitfalls."
          },
          {
            type: "paragraph",
            title: "",
            text: "What sets CompliCheck apart is its focus on privacy and security. Unlike other solutions that rely on third-party LLMs, CompliCheck is designed to protect your company’s sensitive information. All analyses are conducted in-house, ensuring that no internal policy data is shared or exposed to external platforms. This makes it an ideal choice for organizations that prioritize data confidentiality while still needing powerful tools to assess compliance."
          },
          {
            type: "paragraph",
            title: "",
            text: "In addition to its privacy features, CompliCheck is user-friendly and efficient. By providing clear insights into where your policies align with government guidelines and where they fall short, it saves your team valuable time and resources. Instead of manually combing through lengthy documents, you can rely on CompliCheck to deliver accurate, actionable information, enabling you to quickly address any compliance gaps and stay ahead of regulatory changes"
          },
        ]
      },
      // {
      //   id: "5",
      //   title: "Predictive Analysis",
      //   description:
      //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
      //   image: work5,
      //   link: "/research",
      // },
      // {
      //   id: "6",
      //   title: "LLM chaining",
      //   description:
      //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
      //   image: work6,
      //   link: "/research",
      // },
    ];

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const blogId = queryParams.get("id");

    if (blogId) {
      console.log(blogId, "blogId");

      // Find the corresponding blog from your data
      const blog = blogs.find((blog) => blog.id === blogId);
      console.log(blog, "blog");

      if (blog) {
        this.setState({ blog });
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    const { blog } = this.state;

    if (!blog) {
      return <p>Loading...</p>; // Show loading or error message if blog not found
    }
    return (
      <React.Fragment>
        <section className="bg-half">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <div className="section-title">
                  <div className="text-center">
                    <h4 className="title mb-4">{blog.title}</h4>
                    <img
                      src={blog.image}
                      className="img-fluid rounded-md shadow-md mb-2"
                      alt="#"
                    />
                    <p className=" mb-0 mt-4">{blog.description}</p>
                  </div>
                  {/* Render content dynamically */}
                   {blog.content.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.title && <h5 className="pt-4">{item.title}</h5>}
                        <p className=" mb-0 mt-4">{item.text}</p>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-3">Read More</h4>
                </div>
              </Col>
            </Row>

            <Row>
              {this.state.researchExperiments &&
                this.state.researchExperiments
                  .filter((blogs) => blogs.id !== blog.id)
                  .slice(0, 3) // Limit the number of blogs to show
                  .map((blog1, key) => (
                    <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                      <Card
                        className=" border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden"
                        // style={{ height: "38rem" }}
                      >
                        <img
                          src={blog1.image}
                          className="img-fluid"
                          style={{ height: "18rem", width: "30rem" }}
                          alt="work"
                        />
                        <CardBody>
                          <div className="content">
                            <h5 className="mt-3">
                              <h5 className="text-dark title">
                                <a
                                  href={`/research-details?id=${blog1.id}`}
                                  className="text-dark"
                                  onClick={() => window.location.reload()}
                                >
                                  {blog1.title}
                                </a>
                              </h5>
                            </h5>

                            <a
                              href={`/research-details?id=${blog1.id}`}
                              className="text-primary readmore"
                              onClick={() => window.location.reload()}
                            >
                              Read More
                              <i className="uil uil-angle-right-b align-middle"></i>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
