/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
//Import components
import SectionTitle from "../../components/Shared/SectionTitle";
import ReviewsSlider from "../../components/Shared/ReviewsSlider";
import { reviews } from "../../common/data";

//Import Images
import work1 from "../../assets/images/portfolio/jatana-portfolio.png";
import work2 from "../../assets/images/portfolio/aiq.png";
import work3 from "../../assets/images/portfolio/ekhoo.png";
import work4 from "../../assets/images/portfolio/wiser.png";
import work5 from "../../assets/images/portfolio/greviance.png";
import work6 from "../../assets/images/portfolio/wiser.png";
import SI1 from "../../assets/images/servicesIcons/data-table-reference.svg";
import SI2 from "../../assets/images/servicesIcons/security.svg";
import SI3 from "../../assets/images/servicesIcons/data.svg";
import SI4 from "../../assets/images/servicesIcons/laptop-code.svg";
import SI5 from "../../assets/images/servicesIcons/cloud-computing.svg";
import SI6 from "../../assets/images/servicesIcons/communication-unified.svg";
import amazon from "../../assets/images/client/amazon.svg";
import google from "../../assets/images/client/google.svg";
import lenovo from "../../assets/images/client/lenovo.svg";
import paypal from "../../assets/images/client/paypal.svg";
import shopify from "../../assets/images/client/shopify.svg";
import spotify from "../../assets/images/client/spotify.svg";
import work11 from "../../assets/images/work/7.jpg";
import work21 from "../../assets/images/work/8.jpg";
import work31 from "../../assets/images/work/9.jpg";
import work41 from "../../assets/images/work/12.jpg";
import "./common.css";

const PageServices = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [partners] = useState([
    {
      id: 1,
      img: amazon,
    },
    {
      id: 2,
      img: google,
    },
    {
      id: 3,
      img: lenovo,
    },
    {
      id: 4,
      img: paypal,
    },
    {
      id: 5,
      img: shopify,
    },
    {
      id: 6,
      img: spotify,
    },
  ]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const features = [
    {
      id: 1,
      imgUrl: SI1,
      title: "Data Engineering",
      description:
        "Data is new oil. Industry generates lot of data but dont know how to use it to gain insights. We mine the data and put insights in front of you.",
    },
    {
      id: 2,
      imgUrl: SI2,
      title: "Security & Reliability",
      description:
        "We help enhance the security of your current system and ensure the services are always available for your customers.",
    },
    {
      id: 3,
      imgUrl: SI3,
      title: "AI|Machine Learning |NLP",
      description:
        "We help you to identify likelihood of future outcomes using data, statistics, and machine learning techniques.",
    },
    {
      id: 4,
      imgUrl: SI4,
      title: "Research & Development",
      description:
        "We assist you in all the emerging technologies with our inhouse experts and researchers.",
    },
    {
      id: 5,
      imgUrl: SI5,
      title: "Cloud Computing",
      description:
        "We use cloud services to enable fast development, create robust and scalable solutions for our customers.",
    },
    {
      id: 6,
      imgUrl: SI6,
      title: "Communication & Availability",
      description:
        "Our team is always available and responsive to clients request. This ensures to keep high quality services delivery to our clients.",
    },
  ];
  const works = [
    {
      imgUrl: work1,
      title: "Jatana AI",
      subtitle: "Studio",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
    {
      imgUrl: work2,
      title: "Realtime Air Quality Monitoring",
      subtitle: "Web Design",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
    {
      imgUrl: work3,
      title: "Video Calling Customer Service (HIPAA)",
      subtitle: "Developing",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
    {
      imgUrl: work4,
      title: "Data Extraction and Analytics at SCALE",
      subtitle: "Branding",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
    {
      imgUrl: work5,
      title: "Tablepop Event Planner Platform",
      subtitle: "Branding",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
    {
      imgUrl: work6,
      title: "Greviance Redressal System",
      subtitle: "Branding",
      author: "Calvin Carlo",
      date: "13th August, 2019",
    },
  ];
  useEffect(() => {
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        // document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        // document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      }
    }
  };
  return (
    <React.Fragment>
      <section className="mt-5 w-100">
        <div className="hero">
          <h1 className="hero__title">Services</h1>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
        </div>
      </section>
      {/* breadcrumb */}
      {/* <section className="bg-half-170 bg-light d-table w-100">
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h4 className="title mb-0"> Services </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row>
            {features.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-5 ">
                <div className="features feature-primary">
                  <div className="image position-relative d-inline-block">
                    <img
                      src={item.imgUrl}
                      className="img-fluid rounded"
                      alt="work"
                    />
                  </div>

                  <div className="content mt-4">
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <Container className="mt-100 mt-60">
          <SectionTitle
            title="Client Reviews"
            desc="that can provide everything you need to generate awareness, drive traffic, connect."
          />

          <ReviewsSlider reviews={reviews} colClassName="mt-4" />
        </Container>
      </section>

      {/* <section className="section bg-light">
        <Container>
       
          <SectionTitle
            title="Our Latest Projects"
            desc="that can provide everything you need to generate awareness, drive traffic, connect."
          />

          <Row>
            {works.map((work, key) => (
              <Col key={key} md={6} xs={12} className="mt-4 pt-2">
                <Card
                  className="work-container work-modern position-relative overflow-hidden shadow rounded border-0"
                  style={{
                    height: "20rem",
                  }}
                >
                  <CardBody className=" p-0 height-200px">
                    <img
                      src={work.imgUrl}
                      className="img-fluid rounded"
                      alt="work"
                    />
                    <div className="overlay-work bg-dark"></div>
                    <div className="content">
                      <Link
                        to="page-work-detail"
                        className="title text-white d-block fw-bold"
                      >
                        {work.title}
                      </Link>
                      <small className="text-light">{work.subtitle}</small>
                    </div>
                  
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">
                  High quality & critical software development services at your
                  fingertips!
                </h4>
                <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">IAastha</span> that can
                  provide everything you need to generate awareness, drive
                  traffic, connect.
                </p>
                <div className="mt-4">
                  <Link
                    to="/page-contact-three"
                    className="btn btn-primary mt-2 me-2"
                  >
                    Get Started Now
                  </Link>{" "}
              
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="section bg-light">
        <Container className="">
          <Row className="align-items-end mb-4 pb-4">
            <Col md={4}></Col>
            <Col md={4}>
              <div className="section-title text-center text-md-center">
                <h4 className="text-primary">Services</h4>
                <h5 className="title mb-2">What we do ?</h5>
                <p className="text-muted mb-0 para-desc">
                  We create the ideas you have.
                </p>
              </div>
              <div className="text-center text-md-center mt-4">
                <Link to="/page-services" className="text-primary h6">
                  See More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </Col>

            <Col md={4} className="mt-4 mt-sm-0"></Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "1" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">Data Engineering</h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "2" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">Security & Reliability</h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "3" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">AI|Machine Learning |NLP</h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "4" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">Research & Development</h6>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="mt-2">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "5" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">Cloud Computing</h6>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="mt-2">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "6" },
                      "rounded",
                    )}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    <div className="text-center py-1">
                      <h6 className="mb-0">Communication & Availability</h6>
                    </div>
                  </NavLink>
                </NavItem>
              </ul>
            </Col>

            <Col md={8} xs={12} className="mt-4 pt-2">
              <TabContent activeTab={activeTab}>
                <TabPane
                  className="fade bg-white show p-4 rounded shadow"
                  tabId="1"
                >
                  <img
                    src={work11}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>

                <TabPane
                  className="fade bg-white p-4 show rounded shadow"
                  tabId="2"
                >
                  <img
                    src={work21}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>

                <TabPane
                  className="fade bg-white p-4  show rounded shadow"
                  tabId="3"
                >
                  <img
                    src={work31}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>

                <TabPane
                  className="fade bg-white show p-4 rounded shadow"
                  tabId="4"
                >
                  <img
                    src={work41}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>
                <TabPane
                  className="fade bg-white show p-4 rounded shadow"
                  tabId="5"
                >
                  <img
                    src={work31}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>
                <TabPane
                  className="fade bg-white show p-4 rounded shadow"
                  tabId="6"
                >
                  <img
                    src={work11}
                    className="img-fluid rounded shadow"
                    alt=""
                  />
                  <div className="mt-4">
                    <p className="text-muted">
                      This is required when, for example, the final text is not
                      yet available. Dummy text is also known as &apos;fill
                      text&apos;. It is said that song composers of the past
                      used dummy texts as lyrics.
                    </p>
                    <Link to="/portfolio-detail-four" className="text-primary">
                      See More{" "}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </Link>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageServices;
