/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Container,
  Form,
  Modal,
  ModalBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import logoIaastha from "../../assets/images/logoIaastha.jpeg";
import iaastha_logo_transparent from "../../assets/images/iaastha_logo_transparent.png";
import logoIaasthaPng from "../../assets/images/logoIaasthaPng.png";
import shop1 from "../../assets/images/shop/product/s-1.jpg";
import shop2 from "../../assets/images/shop/product/s-2.jpg";
import shop3 from "../../assets/images/shop/product/s-3.jpg";

import NavbarButtons from "../Shared/NavbarButtons";
import appStore from "../../assets/images/app/app-store.png";
import playStore from "../../assets/images/app/play-store.png";

import corporate from "../../assets/images/demo/corporate.png";
import crypto from "../../assets/images/demo/crypto.png";
import shop from "../../assets/images/demo/shop.png";
import portfolio from "../../assets/images/demo/portfolio.png";
import helpCenter from "../../assets/images/demo/help-center.png";
import hosting from "../../assets/images/demo/hosting.png";
import job from "../../assets/images/demo/job.png";
import forums from "../../assets/images/demo/forums.png";
import blog from "../../assets/images/demo/blog.png";
import nft from "../../assets/images/demo/nft.png";
import RightSidebar from "./RightSidebar";
import withRouter from "../../common/data/withRouter";

// menu
// import { navLinks } from './menu';
// import { MenuItem } from './NavBarComponents';

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: "end",
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
      isOffcanvasOpen: false,
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
    this.removeActivation = this.removeActivation.bind(this);
  }

  /**
   * Right sidebar drawer
   **/

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  closeOffcanvas = () => {
    this.setState({ isOffcanvasOpen: false });
  };

  toggleRightDrawer = () => {
    this.setState({ position: "right" });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  // Topbar Page Activation

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName("sub-menu-item");

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].pathname === window.location.pathname) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        const immediateParent = matchingMenuItem.closest("li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }
        var parent = matchingMenuItem.closest(".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector("a");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = parent.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          parentOfParent = matchingMenuItem.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
      return false;
    }
    return false;
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
    this.initializeNavigation();
  }

  initializeNavigation = () => {
    const pathName = window.location.pathname;
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    this.removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  };

  removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  render() {
    const { isOffcanvasOpen } = this.state;

    return (
      <React.Fragment>
        {this.props.tagline ? this.props.tagline : null}

        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              {/* logo */}
              {this.props.hasDarkTopBar ? (
                <Link className="logo" to="/">
                  <img
                    src={iaastha_logo_transparent}
                    height="30"
                    className="logo-light-mode"
                    alt=""
                  />
                  <img
                    src={logoIaasthaPng}
                    height="30"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              ) : (
                <Link className="logo" to="/">
                  <span className="logo-light-mode">
                    <img
                      src={logoIaasthaPng}
                      className="l-dark"
                      height="30"
                      alt=""
                    />
                    <img
                      src={iaastha_logo_transparent}
                      className="l-light"
                      height="30"
                      alt=""
                    />
                  </span>
                  <img
                    src={logoIaasthaPng}
                    height="30"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              )}
            </div>

            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            {(() => {
              if (this.props.router.location.pathname === "/index-developer") {
                return <NavbarButtons />;
              } else if (
                this.props.router.location.pathname === "/index-it-solution-two"
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}
                      >
                        <DropdownToggle
                          color="none"
                          type="button"
                          className="btn-link text-decoration-none dropdown-toggle p-0 pe-2"
                        >
                          <i className="uil uil-search text-muted"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          end
                          className="dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
                          style={{ width: "300px" }}
                        >
                          <div className="search-bar">
                            <Form className="searchform">
                              <input
                                type="text"
                                id="text"
                                name="name"
                                className="form-control border rounded"
                                placeholder="Search..."
                              />
                            </Form>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </li>{" "}
                    <li className="list-inline-item mb-0">
                      <Link to="#" onClick={this.toggleOffcanvas}>
                        <div className="btn btn-icon btn-pills btn-primary settingbtn">
                          <FeatherIcon
                            icon="settings"
                            className="fea icon-sm"
                          />
                        </div>
                      </Link>
                    </li>{" "}
                  </ul>
                );
              } else if (
                this.props.router.location.pathname === "/shop-grids" ||
                this.props.router.location.pathname === "/shop-lists"
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}
                      >
                        <DropdownToggle
                          direction="right"
                          color="none"
                          type="button"
                          className="btn btn-link text-decoration-none p-0 pe-2"
                        >
                          <i className="mdi mdi-magnify h4 text-muted"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          end
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-0"
                          style={{ width: "300px" }}
                        >
                          <Form>
                            <input
                              type="text"
                              id="text"
                              name="name"
                              className="form-control border bg-white"
                              placeholder="Search..."
                            />
                          </Form>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li className="list-inline-item mb-0 pe-1">
                      <Dropdown
                        isOpen={this.state.dropdownOpenShop}
                        toggle={this.toggleDropdownShop}
                      >
                        <DropdownToggle
                          type="button"
                          className="btn btn-icon btn-soft-primary "
                        >
                          <i className="uil uil-shopping-cart align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 p-4"
                          style={{ width: "300px" }}
                        >
                          <div className="pb-4">
                            <Link to="#" className="media align-items-center">
                              <img
                                src={shop1}
                                className="shadow rounded"
                                style={{ maxWidth: "64px" }}
                                alt=""
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">T-shirt (M)</h6>
                                <p className="text-muted mb-0">$320 X 2</p>
                              </div>
                              <h6 className="text-dark mb-0">$640</h6>
                            </Link>

                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop2}
                                className="shadow rounded"
                                alt=""
                                style={{ maxWidth: "64px" }}
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">Bag</h6>
                                <p className="text-muted mb-0">$50 X 5</p>
                              </div>
                              <h6 className="text-dark mb-0">$250</h6>
                            </Link>

                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop3}
                                className="shadow rounded"
                                style={{ maxWidth: "64px" }}
                                alt=""
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">Watch (Men)</h6>
                                <p className="text-muted mb-0">$800 X 1</p>
                              </div>
                              <h6 className="text-dark mb-0">$800</h6>
                            </Link>
                          </div>

                          <div className="media align-items-center justify-content-between pt-4 border-top">
                            <h6 className="text-dark mb-0">Total($):</h6>
                            <h6 className="text-dark mb-0">$1690</h6>
                          </div>

                          <div className="mt-3 text-center">
                            <Link to="#" className="btn btn-primary me-2">
                              View Cart
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Checkout
                            </Link>
                          </div>
                          <p className="text-muted text-start mt-1 mb-0">
                            *T&C Apply
                          </p>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li className="list-inline-item mb-0 pe-1">
                      <Link
                        to="#"
                        className="btn btn-icon btn-soft-primary"
                        onClick={this.toggleWishlistModal}
                      >
                        <i className="uil uil-heart align-middle icons"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        color="primary"
                        isOpen={this.state.dropdownIsOpen}
                        toggle={this.toggleDropdownIsOpen}
                      >
                        <DropdownToggle
                          type="button"
                          className="btn btn-icon btn-soft-primary"
                        >
                          <i className="uil uil-user align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-3"
                          style={{ width: "200px" }}
                        >
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-user align-middle me-1"></i>{" "}
                            Account
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-clipboard-notes align-middle me-1"></i>{" "}
                            Order History
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-arrow-circle-down align-middle me-1"></i>{" "}
                            Download
                          </Link>
                          <div className="dropdown-divider my-3 border-top"></div>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-sign-out-alt align-middle me-1"></i>{" "}
                            Logout
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  </ul>
                );
              } else if (
                this.props.router.location.pathname === "/index-apps" ||
                this.props.router.location.pathname === "/index-classic-app" ||
                this.props.router.location.pathname === "/index-job"
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Link to="#" className="btn btn-icon btn-light">
                        <img
                          src={appStore}
                          className="avatar avatar-ex-small p-1"
                          alt=""
                        />
                      </Link>
                    </li>{" "}
                    <li className="list-inline-item mb-0">
                      <Link to="#" className="btn btn-icon btn-light">
                        <img
                          src={playStore}
                          className="avatar avatar-ex-small p-1"
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                );
              } else {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      {/* <Link
                        to="#"
                        onClick={this.toggleOffcanvas} disabled={this.state.open}
                      >
                        <div
                          id="buyButton"
                          className="btn btn-icon btn-pills btn-soft-primary settingbtn"
                        >
                          <FeatherIcon
                            icon="settings"
                            className="fea icon-sm"
                          />
                        </div>
                      </Link> */}
                      <li>
                        <div className=" pt-2">
                          <Link to="/contact" className="btn btn-primary">
                            Contact Us
                          </Link>
                        </div>
                      </li>
                    </li>{" "}
                  </ul>
                );
              }
            })()}

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              {/* Menu Dropdown */}
              <ul className="navigation-menu nav-dark" id="top-menu">
                {/* home */}
                <li>
                  <Link
                    to="/home"
                    // className="sub-menu-item"
                    onClick={this.toggleLine}
                    className={
                      this.state.isOpen
                        ? "navbar-toggle open sub-menu-item"
                        : "navbar-toggle"
                    }
                  >
                    Home
                  </Link>
                </li>
                {/* <li><Link to="/index-services" className="sub-menu-item">Service</Link></li> */}
                {/* <li><Link to="/index-business" className="sub-menu-item">Business</Link></li> */}
                {/* <li><Link to="/index-landing-two" className="sub-menu-item">Landing </Link></li> */}

                <li>
                  <Link
                    to="/about"
                    // className="sub-menu-item"
                    onClick={this.toggleLine}
                    className={
                      this.state.isOpen
                        ? "navbar-toggle open sub-menu-item"
                        : "navbar-toggle"
                    }
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/research"
                    // to="https://medium.com/iaastha"
                    // onClick={() => this.setState({ blog: !this.state.blog })}
                    // className="sub-menu-item"
                    onClick={this.toggleLine}
                    className={
                      this.state.isOpen
                        ? "navbar-toggle open sub-menu-item"
                        : "navbar-toggle"
                    }
                  >
                    R&D
                  </Link>
                </li>
                <li>
                  <Link
                    to="/all-cases"
                    // className="sub-menu-item"
                    onClick={this.toggleLine}
                    className={
                      this.state.isOpen
                        ? "navbar-toggle open sub-menu-item"
                        : "navbar-toggle"
                    }
                  >
                    Case Studies
                  </Link>
                </li>
                {/* Pages */}
                {/* <li className="has-submenu parent-parent-menu-item">
                  <Link to="#" onClick={() => this.setState({ pages: !this.state.pages })}>Pages</Link><span className="menu-arrow"></span>
                  <ul className={classnames("submenu", { open: this.state.pages })}>
                    <li className="has-submenu parent-menu-item"><Link to="#" onClick={() => this.setState({ company: !this.state.company })}> Company </Link><span className="submenu-arrow"></span>
                      <ul className={classnames("submenu", { open: this.state.company })}>
                        <li><Link to="/about" className="sub-menu-item"> About Us </Link></li>

                        <li><Link to="/page-history" className="sub-menu-item">History </Link></li>
                        <li><Link to="/page-team" className="sub-menu-item"> Team</Link></li>
                      </ul>
                    </li>
                 
                    <li className="has-submenu parent-menu-item"><Link to="https://medium.com/iaastha" onClick={() => this.setState({ blog: !this.state.blog })}> Blog </Link>
                     
                    </li>
          
                    <li><Link to="/all-cases" className="sub-menu-item">Case Study </Link></li>
                 

                  </ul>
                </li> */}
                {/* <li><Link to="/index-portfolio" className="sub-menu-item">Portfolio</Link></li> */}
                {/* <li>
                  <Link to="/index-job" className="sub-menu-item">
                    Jobs & Careers
                  </Link>
                </li> */}
              </ul>

              {/* <ul className="navigation-menu" id="top-menu">
                {(navLinks || []).map((item, key) => {
                  const hasChildren = item.child && item.child.length;
                  return (
                    <MenuItem
                      item={item}
                      key={key}
                      itemClassName={hasChildren ? 'parent-parent-menu-item' : ''}
                      arrowClassName="menu-arrow"
                    />
                  );
                })}
              </ul> */}
              {/* menu end */}
              {/* <div className="buy-menu-btn d-none">
                <Link
                  to="//1.envato.market/landrickreactjs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Buy Now
                </Link>
              </div> */}
            </div>
          </Container>
        </header>

        <Modal
          isOpen={this.state.wishlistModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleWishlistModal}
        >
          <ModalBody className="py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <h1 className="mb-0">
                  <i className="uil uil-heart-break align-middle"></i>
                </h1>
              </div>
              <div className="mt-4">
                <h4>Your wishlist is empty.</h4>
                <p className="text-muted">
                  Create your first wishlist request...
                </p>
                <div className="mt-4">
                  <Link to="#" className="btn btn-outline-primary">
                    + Create new wishlist
                  </Link>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Side Offcanvas */}
        <Offcanvas
          isOpen={isOffcanvasOpen}
          direction={this.state.position}
          toggle={this.toggleOffcanvas}
        >
          <OffcanvasBody>
            <RightSidebar onClose={this.closeOffcanvas} />
          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
