/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef, react/no-unescaped-entities */
/*eslint-disable-next-line no-undef */

import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "../../../../node_modules/react-image-lightbox/style.css";

//Fade in effect
import FadeIn from "react-fade-in";

//Import Images
import bgImg from "../../../assets/images/work/v915.jpg";
import work1 from "../../../assets/images/blog/e-comm-extraction_1.png";
import work2 from "../../../assets/images/blog/nexus-ocean.png";
import work3 from "../../../assets/images/blog/Jatana.gif";
import work4 from "../../../assets/images/blog/Jatana1.png";
import work5 from "../../../assets/images/blog/lockdown.png";
import work6 from "../../../assets/images/portfolio/greviance.png";
import work7 from "../../../assets/images/portfolio/EkhooExpert.png";
import work8 from "../../../assets/images/work/8.jpg";
import work9 from "../../../assets/images/work/9.jpg";
import illustrator from "../../../assets/images/illustrator/Asset190.svg";
import illustrator2 from "../../../assets/images/illustrator/Asset189.svg";
import dot from "../../../assets/images/work/Square.png";

class AllCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "Landrick", link: "/index" },
        { id: 2, name: "Pages", link: "#" },
        { id: 3, name: "Work", link: "#" },
        { id: 4, name: "Classic" },
      ],

      projects: [
        {
          image: work1,
          title: "Jatana AI",
          subtitle: "AI Automations for better Customer Experiences.",
          category: "Branding",
          link: "/page-case-detail",
        },
        {
          image: work2,
          title: "Realtime Air Quality Monitoring",
          subtitle:
            "India’s first affordable PM2.5 monitor with free data and dashboard",
          category: "Designing",
          link: "/page-case-detail",
        },
        {
          image: work3,
          title: "Video Calling Customer Service (HIPAA)",
          subtitle: "Bring customer and businesses closed during a pandemic",
          category: "Photography",
          link: "/page-case-detail",
        },
        {
          image: work4,
          title: "Data Extraction and Analytics at SCALE",
          subtitle: "Bringing e-commerce data visibility to businesses.",
          category: "Development",
          link: "/page-case-detail",
        },
        {
          image: work5,
          title: "Tablepop Event Planner Platform",
          subtitle:
            "An event rental platform changing the way people design their entertainment experiences.",
          category: "Branding",
          link: "/page-case-detail",
        },
        {
          image: work6,
          title: "Greviance Redressal System",
          subtitle: "Our solutions Assisting AI company based in London",
          category: "Branding",
          link: "/page-case-detail",
        },
        {
          image: work7,
          title: "Cloud Call Center",
          subtitle: "Call center community for companies and freelancers",
          category: "Designing",
          link: "/page-case-detail",
        },
        // { image: work8, title: "Pen and article", subtitle: "Article", category: "Development" },
      ],
      blogs:
        // [
        //   {
        //     id: 1,
        //     title: "Jatana AI",
        //     author: "John Doe",
        //     date: "2024-08-13",
        //     imageUrl: work1,
        //     summary: "AI Automations for better Customer Experiences.",
        //     content: [
        //       {
        //         type: "paragraph",
        //         text: "Artificial Intelligence (AI) has made significant strides in 2024, revolutionizing various industries and transforming the way we live and work. From healthcare to finance, AI technologies are being integrated to enhance efficiency, accuracy, and decision-making processes. In healthcare, AI-powered diagnostic tools are enabling early detection of diseases, personalized treatment plans, and improved patient outcomes. In finance, AI algorithms are optimizing trading strategies, detecting fraudulent activities, and providing personalized financial advice. Moreover, AI is playing a crucial role in advancing autonomous vehicles, smart cities, and robotics, paving the way for a more connected and automated future. As AI continues to evolve, ethical considerations and regulatory frameworks are becoming increasingly important to ensure responsible and fair use of these powerful technologies.",
        //       },
        //       {
        //         type: "image",
        //         url: illustrator,
        //         caption: "AI growth over the years",
        //       },
        //       {
        //         type: "paragraph",
        //         text: "Artificial Intelligence (AI) has made significant strides in 2024, revolutionizing various industries and transforming the way we live and work. From healthcare to finance, AI technologies are being integrated to enhance efficiency, accuracy, and decision-making processes. In healthcare, AI-powered diagnostic tools are enabling early detection of diseases, personalized treatment plans, and improved patient outcomes. In finance, AI algorithms are optimizing trading strategies, detecting fraudulent activities, and providing personalized financial advice. Moreover, AI is playing a crucial role in advancing autonomous vehicles, smart cities, and robotics, paving the way for a more connected and automated future. As AI continues to evolve, ethical considerations and regulatory frameworks are becoming increasingly important to ensure responsible and fair use of these powerful technologies.",
        //       },
        //     ],
        //     tags: ["AI", "Technology", "2024"],
        //   },
        //   {
        //     id: 2,
        //     title: "Realtime Air Quality Monitoring",
        //     author: "Jane Smith",
        //     date: "2024-08-10",
        //     imageUrl: work2,
        //     summary:
        //       "India’s first affordable PM2.5 monitor with free data and dashboard",
        //     content: [
        //       {
        //         type: "paragraph",
        //         text: "India’s first affordable PM2.5 monitor with free data and dashboard. This innovative device is designed to provide accurate and real-time air quality monitoring, making it accessible to a wider audience. The monitor not only measures PM2.5 levels but also offers insights into other pollutants, helping users understand the air quality in their surroundings. With its user-friendly dashboard, individuals and communities can easily track air quality trends, receive alerts, and take necessary actions to protect their health. This initiative is a significant step towards raising awareness about air pollution and empowering people with the information they need to make informed decisions about their environment.",
        //       },
        //       {
        //         type: "image",
        //         url: illustrator2,
        //         caption: "Modern web architecture",
        //       },
        //       {
        //         type: "paragraph",
        //         text: "India’s first affordable PM2.5 monitor with free data and dashboard. This innovative device is designed to provide accurate and real-time air quality monitoring, making it accessible to a wider audience. The monitor not only measures PM2.5 levels but also offers insights into other pollutants, helping users understand the air quality in their surroundings. With its user-friendly dashboard, individuals and communities can easily track air quality trends, receive alerts, and take necessary actions to protect their health. This initiative is a significant step towards raising awareness about air pollution and empowering people with the information they need to make informed decisions about their environment.",
        //       },
        //     ],
        //     tags: ["Web Development", "Trends", "Technology"],
        //   },
        // ],
        [
          {
            id: 1,
            title: "E-Commerce Data Extraction @ Scale",
            imageUrl: work1,
            summary:
              "Our client, a leading analytics company in Europe, sought to automate the extraction and analysis of product data across multiple merchants. The primary goal was to extract product SKUs, prices, and availability on a daily basis, and to perform internal product-to-product matching to track pricing trends.",
            content: [
              {
                type: "paragraph",
                title: "Challenge",
                text: "",
                items: [],
              },

              {
                type: "list",
                items: [
                  {
                    boldText: "High Volume of Data:",
                    normalText:
                      "The client needed to process a large and continually growing volume of product data from multiple sources every day, making scalability a primary concern.",
                  },
                  {
                    boldText: "Accuracy in Data Extraction:",
                    normalText:
                      "With prices and product availability constantly fluctuating, the client required an extremely accurate and reliable extraction system.",
                  },
                  {
                    boldText: "Product Matching Complexity:",
                    normalText:
                      "Matching the same products across different merchants, where product names and descriptions could vary significantly, posed a significant challenge.",
                  },
                  {
                    boldText: "Real-Time Monitoring and Analytics:",
                    normalText:
                      "Continuous monitoring of extraction performance and immediate insights into pricing trends were essential to maintain the quality and timeliness of the data.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Solution",
                items: [],
                text: "We developed an end-to-end data extraction and processing solution tailored to the client's needs, which involved the following components:",
              },
              {
                type: "paragraph",
                title: "1. Data Extraction Engine",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "A centralized Taxonomy DB was maintained and regularly updated by the team. This database categorized and defined the products and SKUs to be extracted, along with the extraction frequency for each merchant. It served as the foundation for ensuring that the right data was being extracted at the right time.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "2. Extraction Scheduler",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "The Extraction Scheduler orchestrated the daily extraction processes, pulling data from the Taxonomy DB to determine the extraction tasks. It managed the scheduling and execution of extraction jobs, ensuring that the system remained aligned with the predefined schedules.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "3. Data Extraction Service",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "At the core of the solution was the Data Extraction Service, which handled the actual extraction of product data, including SKUs, prices, and availability from various merchant websites. This service was built to scale horizontally, enabling it to process vast amounts of data efficiently and accurately.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "4. Job and Seed Databases",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "The Job DB tracked the status of each extraction job, providing a comprehensive overview of the ongoing and completed tasks. The Seed DB stored configuration and seed data required to initialize and execute the extraction tasks, ensuring consistency and accuracy.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "5. Post-Processing and Analytics",
                items: [
                  {
                    boldText: "",
                    normalText:
                      " After extraction, data underwent a rigorous post-processing phase via the Extraction Post Processing Service. This service ensured data consistency, validated extraction results, and prepared the data for further analysis.",
                  },
                  {
                    boldText: "",
                    normalText:
                      "The Extraction Performance Analytics module provided real-time insights into the extraction process, allowing for continuous monitoring and optimization. This ensured that the data was accurate and extraction processes remained efficient.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "6. Product Matching and Trend Analysis",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "A critical aspect of the solution was the internal Product Matching process, where products extracted from different merchants were matched based on various parameters. This allowed the client to track pricing trends and availability across different platforms, providing valuable market insights.",
                  },
                  {
                    boldText: "AI-Powered Analysis:",
                    normalText:
                      "Leveraging an AI server with an embeddings database, the system performed advanced analytics to identify patterns and trends in pricing, offering deeper insights into market dynamics.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "7. Storage and API Integration",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "Extracted and processed data was stored in the Product Database. This database served as the primary source for both internal analysis and external API consumption.",
                  },
                  {
                    boldText: "",
                    normalText:
                      "The Product Backend API provided clients with seamless access to the processed data, allowing them to integrate it directly into their systems. Cached data storage was implemented to reduce latency and ensure quick data retrieval.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "8. Frontend Dashboard",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "Users could visualize and interact with the extracted data through a user-friendly Frontend Dashboard. This dashboard provided a comprehensive view of the data, including pricing trends, product availability, and other key metrics.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "Results",
                text: "",
                items: [],
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Scalable Solution:",
                    normalText:
                      "The automated extraction system allowed the client to handle a significant increase in data volume as their business grew, without compromising on accuracy or performance.",
                  },
                  {
                    boldText: "High Accuracy:",
                    normalText:
                      "The rigorous post-processing and analytics ensured that the extracted data was accurate, reliable, and timely, enabling the client to make informed business decisions.",
                  },
                  {
                    boldText: "Effective Product Matching:",
                    normalText:
                      "The advanced product matching algorithms, coupled with AI-powered analysis, provided the client with a clear view of pricing trends and product availability across different merchants.",
                  },
                  {
                    boldText: "Improved User Experience:",
                    normalText:
                      "With the integration of a responsive dashboard and API, users could easily access and utilize the data, enhancing their overall experience and satisfaction.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },
              {
                type: "paragraph",
                title: "Conclusion",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "By delivering a scalable, accurate, and efficient data extraction solution, we enabled the client to gain a competitive edge in the e-commerce market. The system not only automated the extraction of critical product data but also provided powerful tools for product matching and trend analysis, offering invaluable insights that drove the client’s business forward.",
                  },
                ],
              },
            ],
            tags: ["Web Development", "Trends", "Technology"],
          },

          {
            id: 2,
            title:
              "Revolutionizing Maritime Operations with AI-Driven Automation",
            imageUrl: work2,
            summary:
              "In the complex and often unpredictable world of maritime shipping, our client—a forward-thinking startup—set out to transform how operational tasks are handled on the high seas. Focused on automating manual operations, particularly those related to the Bill of Lading and other critical operational issues, this startup aimed to alleviate the burdens faced by sailors and traders as they navigate the challenges of transporting goods across oceans.",
            content: [
              {
                type: "paragraph",
                title: "Challenge",
                text: "",
                items: [],
              },

              {
                type: "list",
                items: [
                  {
                    boldText: "Inherent Complexity of Maritime Operations:",
                    normalText:
                      "Shipping goods across international waters involves numerous stakeholders, detailed documentation, and precise coordination. The manual handling of these tasks was both time-consuming and error-prone.",
                  },
                  {
                    boldText: "Data Integration from Multiple Sources:",
                    normalText:
                      "The client needed to pull and process data from various sources, including real-time streaming data and one-time historical data dumps, to create a comprehensive and reliable operational workflow.",
                  },
                  {
                    boldText: "Data Security and Compliance:",
                    normalText:
                      "Managing sensitive information such as Personally Identifiable Information (PII) while adhering to global regulations like GDPR was a major concern, particularly in an industry as sensitive as shipping.",
                  },
                  {
                    boldText:
                      "Model Training and Optimization for Operational Tasks:",
                    normalText:
                      "Deploying AI models that could accurately assist in making operational decisions, optimize workflows, and anticipate challenges required a sophisticated approach to model training and deployment.",
                  },
                  {
                    boldText: "Real-Time Decision Making:",
                    normalText:
                      "For sailors and traders, timely decisions can make or break a shipment's success. The solution needed to provide real-time insights and facilitate immediate action.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Solution",
                items: [],
                text: "Leveraging our intimate understanding of the shipping industry's complexities and our passion for AI-driven industry transformation, we crafted a bespoke solution that integrated AI into the client’s operational workflows, addressing their unique challenges head-on.",
              },
              {
                type: "paragraph",
                title: "1. Data Ingestion and Cleansing",
                items: [
                  {
                    boldText: "Data Ingestion:",
                    normalText:
                      "We set up a robust data ingestion framework that could handle both real-time streaming data from External APIs and one-time data dumps. This ensured that all relevant information was captured, from ship movement data to transactional logs.",
                  },
                  {
                    boldText: "Data Cleansing and Transformation:",
                    normalText:
                      "Given the critical nature of maritime operations, the data was meticulously cleansed and transformed. With the support of AWS Macie, the solution identified and secured sensitive information, ensuring compliance with global data security standards.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "2. Feature Engineering and Model Training",
                items: [
                  {
                    boldText: "Data Wrangling and Feature Engineering:",
                    normalText:
                      "We enhanced the data by implementing custom transformation layers, named entity recognition (NER), and dimensionality reduction techniques. This process ensured that the data was not only clean but also structured in a way that made it actionable for AI-driven insights.",
                  },
                  {
                    boldText: "AI Model Training and Optimization:",
                    normalText:
                      "Using Amazon SageMaker, we developed and optimized machine learning models tailored to the unique needs of the shipping industry. These models were rigorously trained, with hyperparameter tuning and algorithm optimization, to assist in operational decision-making and automate routine tasks.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "3. Deployment and Real-Time Inference",
                items: [
                  {
                    boldText: "Model Deployment and Inference:",
                    normalText:
                      "The trained models were deployed into the client’s environment using a sophisticated deployment pipeline that included vector storage and a mapping database. This setup allowed the models to be quickly accessed and utilized for real-time decision-making via an Inference Layer that exposed an API for usage.",
                  },
                  {
                    boldText: "Frontend Dashboard for Real-Time Monitoring:",
                    normalText:
                      "A user-friendly Frontend Dashboard was developed, enabling sailors, traders, and other stakeholders to monitor operations in real-time. This dashboard provided critical insights, allowing users to make informed decisions and quickly respond to any operational issues.",
                  },
                ],
              },
              {
                type: "paragraph",
                title: "Results",
                text: "",
                items: [],
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Seamless Automation of Operational Tasks:",
                    normalText:
                      "By automating key operational tasks, the client was able to significantly reduce the manual workload on sailors and traders, allowing them to focus on more strategic aspects of their roles.",
                  },
                  {
                    boldText: "Enhanced Decision-Making Capabilities:",
                    normalText:
                      "The real-time data processing and AI-driven insights provided by the solution enabled the client to make faster, more informed decisions, ultimately improving the efficiency and reliability of their shipping operations.",
                  },
                  {
                    boldText: "Improved Compliance and Data Security:",
                    normalText:
                      "The integration of AWS Macie and other security features ensured that all data handling processes were fully compliant with GDPR and other international regulations, protecting sensitive information from unauthorized access.",
                  },
                  {
                    boldText: "Scalable and Adaptable Solution:",
                    normalText:
                      "The AI models and deployment pipeline were designed to scale with the client’s growing needs, making it easy to add new data sources, adapt to new operational requirements, and handle increasing data volumes.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },
              {
                type: "paragraph",
                title: "Conclusion",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "Having experienced the challenges of the maritime industry firsthand, we brought our expertise to bear in creating a solution that not only addressed the client’s immediate operational needs but also laid the foundation for long-term growth and efficiency. By embedding bespoke AI assistants into the client’s workflows, we helped them weather the operational storms of the shipping industry and emerge stronger, more efficient, and better equipped to navigate the complexities of global trade.",
                  },
                ],
              },
            ],
            tags: ["Web Development", "Trends", "Technology"],
          },
          {
            id: 3,
            title: "Empowering Customer Service Automation with Jatana",
            imageUrl: work3,
            summary:
              "Jatana is a cutting-edge customer service automation platform that revolutionizes the way businesses handle support requests. It is designed to integrate seamlessly with popular customer service and CRM systems, with its current integration being with Zendesk. By creating a unique AI engine for each client, Jatana leverages historical data to suggest replies, automate responses, tag and triage tickets, and provide detailed metrics. Being language-agnostic, Jatana is ideal for global teams that offer support in multiple languages. Notably, Jatana was selected to be part of Station F in Paris, one of the world’s largest startup campuses, further validating its innovative approach to customer service automation.",
            content: [
              {
                type: "paragraph",
                title: "Project Objective",
                text: "The client sought to enhance their platform's capabilities and reach by integrating it with Zendesk and expanding their services to B2B clients. This required not only the development of a robust engineering solution but also the migration of vast amounts of historical data from various customer service systems to ensure seamless operation and training of the AI engine.Our Role We were entrusted with the complete engineering of Jatana's platform, specifically focusing on the following key areas:",
                items: [],
              },
              {
                type: "paragraph",
                title: "Our Role",
                text: "We were entrusted with the complete engineering of Jatana's platform, specifically focusing on the following key areas:",
                items: [],
              },

              {
                type: "list",
                items: [
                  {
                    boldText: "Zendesk Integration:",
                    normalText:
                      "We developed a comprehensive Zendesk extension, enabling Jatana to integrate smoothly with Zendesk and providing enhanced functionality for customer service automation.",
                  },
                  {
                    boldText: "Data Migration Services:",
                    normalText:
                      "Our team handled the intricate process of data migration for B2B clients. This involved transferring large volumes of historical data from existing customer service systems to Jatana, ensuring that the AI engines were trained with accurate and relevant information.",
                  },
                  {
                    boldText: "Engineering and Development:",
                    normalText:
                      "We were responsible for the overall engineering of the platform, ensuring that it was scalable, secure, and capable of handling complex customer service automation tasks.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Challenges",
                items: [],
                text: "",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Data Complexity:",
                    normalText:
                      "Migrating data from various customer service systems posed significant challenges due to differences in data structures and formats.",
                  },
                  {
                    boldText: "Scalability:",
                    normalText:
                      "Ensuring that the platform could handle a high volume of tickets and customer interactions without compromising performance.",
                  },
                  {
                    boldText: "Customization:",
                    normalText:
                      "Creating an AI engine tailored to each client’s specific needs, requiring flexible and adaptive engineering solutions.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },
              {
                type: "image",
                url: work4,
                caption: "",
              },
              {
                type: "paragraph",
                title: "Solution and Implementation",
                items: [],
                text: "Our approach to overcoming these challenges included:",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Custom Data Migration Tools:",
                    normalText:
                      "We developed custom tools to automate the data migration process, ensuring accuracy and minimizing downtime.",
                  },
                  {
                    boldText: "Scalable Architecture:",
                    normalText:
                      "We designed the platform architecture to be highly scalable, allowing it to handle increasing loads as Jatana expanded its client base.",
                  },
                  {
                    boldText: "Flexible AI Engine:",
                    normalText:
                      "By working closely with the Jatana team, we ensured that the AI engine was flexible enough to be trained on diverse datasets and deliver accurate results across different languages and support scenarios.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },
              {
                type: "paragraph",
                title: "Results",
                items: [],
                text: "",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Increased Efficiency:",
                    normalText:
                      "The integration with Zendesk enabled Jatana to automate up to 50% of ticket responses, significantly reducing the workload on support agents.",
                    link: "https://www.zendesk.com/in/marketplace/partners/1279/jatana/",
                  },
                  {
                    boldText: "Enhanced Analytics:",
                    normalText:
                      "The platform's ability to provide detailed metrics allowed clients to gain deeper insights into their customer service operations.",
                  },
                  {
                    boldText: "Successful Data Migration:",
                    normalText:
                      "We successfully migrated vast amounts of data without any loss, ensuring that the AI engines were trained effectively.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Conclusion",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "Our collaboration with Jatana resulted in a robust and scalable customer service automation platform that is now ready to integrate with additional customer service and CRM systems. By handling the complete engineering and data migration processes, we enabled Jatana to offer a powerful solution to its B2B clients, helping them deliver faster, more efficient customer service. This project showcases our ability to deliver complex engineering solutions and manage large-scale data migration tasks, providing our clients with the tools they need to succeed in an increasingly automated world.",
                  },
                ],
              },
            ],
            tags: ["Web Development", "Trends", "Technology"],
          },
          {
            id: 4,
            title:
              "Enhancing Air Quality Monitoring with IoT and Data Integration for Atmos",
            imageUrl: work5,
            summary:
              "Atmos, India's first affordable PM2.5 monitor, is at the forefront of environmental science, offering free data and a user-friendly dashboard. This innovative device provides accurate, real-time air quality monitoring, making critical environmental data accessible to a broader audience. In addition to measuring PM2.5 levels, Atmos also tracks other pollutants, enabling users to understand the air quality in their surroundings. The initiative aims to raise awareness about air pollution and empower communities to take informed actions to protect their health.",
            content: [
              {
                type: "paragraph",
                title: "Project Objective",
                text: "The client sought to increase the efficiency and scalability of their data ingestion process from IoT devices deployed across various locations. Additionally, they aimed to enhance data quality and expand their data coverage by integrating supplementary data from government websites, particularly in areas where IoT devices were not installed.",
                items: [],
                link: "https://atmos.urbansciences.in/atmos/maps",
              },
              {
                type: "paragraph",
                title: "Our Role",
                text: "We were responsible for two key areas in this project:",
                items: [],
              },

              {
                type: "list",
                items: [
                  {
                    boldText: "Increasing Throughput of Data Ingestion:",
                    normalText:
                      "We optimized the data ingestion pipeline from Atmos's IoT devices to their servers. This involved enhancing the system's capacity to process and store large volumes of data generated by the devices in real time, ensuring the continuous availability of accurate and up-to-date air quality information.",
                  },
                  {
                    boldText: "Data Extraction from Government Websites:",
                    normalText:
                      "To improve data quality and coverage, we worked with the Atmos team to extract relevant environmental data from government websites. This data served as a supplement to the IoT-generated data, particularly in regions where IoT devices were not deployed, thus providing a more comprehensive picture of air quality across various locations.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Challenges",
                items: [],
                text: "",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Scalability:",
                    normalText:
                      "As the number of IoT devices increased, the existing infrastructure struggled to keep up with the data volume. Optimizing the data pipeline to handle this growth was crucial.",
                  },
                  {
                    boldText: "Data Integration:",
                    normalText:
                      "Extracting and integrating data from government sources presented challenges due to differences in data formats and the need for real-time updates.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Solution and Implementation",
                items: [],
                text: "Our approach to overcoming these challenges included:",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Optimized Data Ingestion Pipeline:",
                    normalText:
                      "We implemented a robust, scalable architecture that could handle increased data throughput from IoT devices. This included optimizing the data flow from devices to servers and ensuring that the system could process and store the data efficiently.",
                  },
                  {
                    boldText: "Automated Data Extraction Tools:",
                    normalText:
                      "We developed automated tools for extracting data from government websites, standardizing the data formats, and integrating it with the IoT data. This approach not only improved data quality but also expanded coverage to areas where IoT devices were absent.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },
              {
                type: "paragraph",
                title: "Results",
                items: [],
                text: "",
              },
              {
                type: "list",
                items: [
                  {
                    boldText: "Improved Data Processing:",
                    normalText:
                      "The optimized data ingestion pipeline significantly increased the system’s ability to handle larger volumes of data, ensuring real-time availability of accurate air quality information.",
                  },
                  {
                    boldText: "Expanded Data Coverage:",
                    normalText:
                      "By integrating data from government sources, Atmos was able to provide a more comprehensive view of air quality, even in regions without IoT device installations.",
                  },
                  {
                    boldText: "Enhanced Data Quality:",
                    normalText:
                      "The combination of IoT-generated data and government-sourced data resulted in higher data accuracy and reliability, empowering users to make better-informed decisions about their environment.",
                  },
                ],
                parentTitle: "",
                subTitle: "",
              },

              {
                type: "paragraph",
                title: "Conclusion",
                items: [
                  {
                    boldText: "",
                    normalText:
                      "Our collaboration with Atmos resulted in a more efficient and scalable air quality monitoring system that leverages both IoT and government data sources. By increasing the throughput of data ingestion and expanding data coverage, we helped Atmos deliver a more comprehensive and reliable air quality monitoring solution to its users. This project underscores our expertise in IoT, data integration, and environmental monitoring, contributing to the success of a vital initiative in raising awareness about air pollution in India. This case study highlights our ability to deliver innovative and scalable solutions in the IoT and environmental science domains, helping clients like Atmos lead the way in making critical environmental data accessible to all.",
                  },
                ],
              },
            ],
            tags: ["Web Development", "Trends", "Technology"],
          },
        ],
      displayCategory: "All",
      photoIndex: 0,
      isOpen: false,
    };
    this.setCategory.bind(this);
  }

  setCategory(category) {
    this.setState({
      displayCategory: category,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light");
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu")?.classList.add("nav-light");
    });
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document
        .querySelector(".settingbtn")
        ?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <React.Fragment>
        {/* breadcrumb */}
        {/* <Container>
          <div
            style={{ position: "relative", width: "500px", height: "500px" }}
          >
            <img
              src={work8}
              alt="Background Image"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <img
              src={dot}
              alt="Transparent Image"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: 0.5,
              }}
            />
          </div>
        </Container> */}
        <section
          className="section bg-light d-table w-100"
          // style={{
          //   background: `url(${bgImg}) center center`,
          //   backgroundSize: "cover",
          //   background:
          //   "linear-gradient(135deg, #6a11cb -50%, #80d0c7 50%, #2575fc 100%)",
          // }}
        >
          {/* <div className="bg-overlay"></div> */}
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title title-dark text-primary mb-0">
                    All Cases
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div> */}

        <section className="">
          <Container>
            <Row id="grid" className="mt-4 justify-content-center">
              {this.state.blogs
                // .filter(
                //   ({ category }) =>
                //     this.state.displayCategory === category ||
                //     this.state.displayCategory === "All",
                // )
                .map(({ title, imageUrl, summary, id }, key) => (
                  <Col
                    lg={8}
                    md={12}
                    xs={12}
                    key={key}
                    className="spacing picture-item "
                  >
                    <FadeIn delay={100}>
                      <Card
                        className=" border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden"
                        // style={{ height: "38rem" }}
                      >
                        <div className="content">
                          <p className="mt-2" style={{ paddingLeft: "1.5rem" }}>
                            <h4 className="text-dark">{title}</h4>
                          </p>
                        </div>
                        <img
                          src={imageUrl}
                          className="img-fluid"
                          // style={{ height: "20rem", width: "30rem" }}
                          alt="work"
                        />
                        <CardBody>
                          <div className="content">
                            {/* <h5 className="mt-3">
                              <h5 className="text-dark title">{title}</h5>
                            </h5> */}
                            <p className="text-muted">{summary}</p>
                            <Link
                              to={`/page-case-detail?id=${id}`}
                              className="link h6"
                            >
                              Read More{" "}
                              <i className="uil uil-angle-right-b align-middle"></i>
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </FadeIn>
                  </Col>
                ))}
            </Row>
            {/* lightbox for portfolio images */}
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                imagePadding={100}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </Container>
          {/* <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">
                    Let&apos;s talk about your portfolio
                  </h4>
                  <p className="text-muted para-desc mx-auto">
                    Start working with{" "}
                    <span className="text-primary fw-bold">Landrick</span> that
                    can provide everything you need to generate awareness, drive
                    traffic, connect.
                  </p>
                  <div className="mt-4 pt-2">
                    <Link to="#" className="btn btn-primary">
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container> */}
        </section>
      </React.Fragment>
    );
  }
}

export default AllCases;
