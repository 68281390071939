// React Basic and Bootstrap
import React, { Component } from "react";
import Footer4 from "../../PageFooterLayouts/Footer4";
import NavBar from "./NavBar";

// Import Generic components
import Section from "./Section";
import Work from "./Work";
import Popup from "../../../../components/Layout/popup";

class Index extends Component {
  // componentDidMount() {
  //   // document.body.classList = "";
  //   // window.addEventListener("scroll", scrollNavigation, true);
  //   // document.getElementById("buyButton")?.classList.add("nav-light");
  //   // document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
  //   window.addEventListener("scroll", scrollNavigation, true);
  // }
  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", scrollNavigation, true);
  // }
  // scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   const navBar = document.getElementById("topnav");
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (navBar != null) {
  //     if (top > 80) {
  //       navBar.classList.add("nav-sticky");
  //     } else {
  //       navBar.classList.remove("nav-sticky");
  //     }
  //   }
  // };
  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <NavBar />
        {/* Hero Start */}
        <Section />

        {/* Work  */}
        <Work />
        <Footer4 />

        {/* <ThemeSwitcher /> */}
        <Popup />
      </React.Fragment>
    );
  }
}

export default Index;
