import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CountUp from "react-countup";
import classnames from "classnames";

//Import Images
import aboutus from "../../assets/images/company/aboutus.jpg";
import about2 from "../../assets/images/company/about2.png";
import amazon from "../../assets/images/client/amazon.svg";
import google from "../../assets/images/client/google.svg";
import lenovo from "../../assets/images/client/lenovo.svg";
import paypal from "../../assets/images/client/paypal.svg";
import shopify from "../../assets/images/client/shopify.svg";
import spotify from "../../assets/images/client/spotify.svg";
import WorkProcess from "../../components/Shared/WorkProcess";
import work1 from "../../assets/images/experiments/image_sort.png";
import work2 from "../../assets/images/experiments/manthan_ai.png";
import work3 from "../../assets/images/experiments/clustify_ai.png";
import work4 from "../../assets/images/experiments/compli_check.png";
import work5 from "../../assets/images/mywork/Predictive1.png";
import work6 from "../../assets/images/mywork/LLM1.png";
import airBills from "../../assets/images/our client/airbills.png";
import billtrim from "../../assets/images/our client/billtrim.png";
import botsupply from "../../assets/images/our client/botsupply.png";
import ekhoo from "../../assets/images/our client/ekhoo.png";
// import jatana from "../../assets/images/our client/jatana.png";
import neuralspace from "../../assets/images/our client/neuralspace.png";
import jatana from "../../assets/images/our client/viralGet.png";
import moxci from "../../assets/images/our client/moxci.png";
import ExperienceTeam from "../CorporateBusiness/ExperienceTeam";
import SectionTitle from "../../components/Shared/SectionTitle";

export default class PageAboutusTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      partners: [
        {
          id: 1,
          img: botsupply,
        },
        {
          id: 2,
          img: moxci,
        },
        {
          id: 3,
          img: billtrim,
        },
        {
          id: 4,
          img: jatana,
        },
        {
          id: 5,
          img: ekhoo,
        },
        // {
        //   id: 6,
        //   img: viralget,
        // },
        // {
        //   id: 7,
        //   img: neuralspace,
        // },
        {
          id: 8,
          img: airBills,
        },
      ],
      researchExperiments: [
        {
          id: "1",
          title: "ImageSort AI",
          description:
            "Because life’s too short to be sorting images manually – let the robots do it!",
          image: work1,
          link: "/research",
        },
        {
          id: "2",
          title: "Manthan AI",
          description:
            "A revolutionary service that transforms the way you interact with GitHub repositories.",
          image: work2,
          link: "/research",
        },
        {
          id: "3",
          title: "Clustify AI",
          description:
            "Analyze textual data in real time by unique clustering algorithm. By focusing on semantic similarity, Clustify AI ensures that your information is organized into distinct, meaningful clusters, eliminating redundancy and overlap. ",
          image: work3,
          link: "/research",
        },
        {
          id: "4",
          title: "CompliCheck",
          description:
            "A groundbreaking service that ensures your company's policy documents align with government guidelines.",
          image: work4,
          link: "/research",
        },
        // {
        //   id: "5",
        //   title: "Predictive Analysis",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work5,
        //   link: "/research",
        // },
        // {
        //   id: "6",
        //   title: "LLM chaining",
        //   description:
        //     "This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.",
        //   image: work6,
        //   link: "/research",
        // },
      ],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light");
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu")?.classList.add("nav-light");
    });

    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation() {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  }
  render() {
    const { activeTab, researchExperiments } = this.state;
    return (
      <React.Fragment>
        {/* <section className="mt-5 w-100">
          <div className="hero">
            <h1 className="hero__title">About us</h1>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
            <div className="cube"></div>
          </div>
        </section> */}
        {/* <section
          className="bg-half-170 d-table w-100"
          style={{ background: `url(${aboutus})` }}
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading title-heading">
                  <h2 className="text-white title-dark"> Aboutus </h2>
                  <p className="text-white-50 para-desc mb-0 mx-auto">
                    We create the ideas that you have.
                  </p>
                </div>
              </Col>
            </Row>
          
          </Container>
        </section> */}
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="align-items-center" id="counter">
              <Col md={6}>
                <img src={about2} className="img-fluid" alt="" />
              </Col>

              <Col md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
                  <div className="d-flex mb-4">
                    <span className="text-primary h1 mb-0 item-center">
                      <span className="counter-value display-1 fw-bold">
                        <CountUp start={10} end={10} />
                      </span>
                    </span>
                    <span className="text-primary h1 mb-0 item-center align-self-center">
                      <span className="counter-value fw-bold">+</span>
                    </span>
                    {/* <span className="h4 align-self-center ms-2">
                      Average Years Experience
                    </span> */}
                    <span className="h4 mb-0 item-center align-self-center ms-2">
                      <span className="counter-value fw-bold">
                        Average Years Experience
                      </span>
                    </span>
                  </div>
                  <div className="section-title">
                    <h4 className="title mb-4">Our Core</h4>
                    <p className="text-muted">
                      We are a team of engineers and researchers passionate
                      about solving complex problems with creative solutions. We
                      work as an in-house team, ensuring complete transparency
                      and collaboration, which minimizes time and effort to help
                      you achieve an early product-market fit.
                    </p>
                    <Link to="/contact" className="btn btn-primary mt-3">
                      Contact us
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-4">
            <Row className="justify-content-center">
              {this.state.partners.map((image, key) => (
                <Col
                  lg={2}
                  md={2}
                  xs={6}
                  className="text-center pt-4"
                  key={key}
                >
                  <img src={image.img} className="avatar avatar-ex-sm" alt="" />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <section className="section bg-light">
          {/* Work process render */}
          <WorkProcess />

          {/* <Container className="mt-100 mt-60">
            <Row className="align-items-end mb-4 pb-4">
              <Col md={4}></Col>
              <Col md={4}>
                <div className="section-title text-center text-md-center">
                  <h5 className="title">Research Implementations</h5>
                 
                </div>
                
              </Col>

              <Col md={4} className="mt-4 mt-sm-0"></Col>
            </Row>

            <Row>
              <Col md={4} className="mt-4 pt-2">
                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                  <NavItem>
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Data Engineering</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "2" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Security & Reliability</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "3" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">AI|Machine Learning |NLP</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="mt-2">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "4" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Research & Development</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mt-2">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "5" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("5");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Cloud Computing</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mt-2">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "6" },
                        "rounded",
                      )}
                      onClick={() => {
                        this.toggle("6");
                      }}
                    >
                      <div className="text-center py-1">
                        <h6 className="mb-0">Communication & Availability</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                </ul>
              </Col>

              <Col md={8} xs={12} className="mt-4 pt-2">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="1"
                  >
                    <img
                      src={work1}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white p-4 show rounded shadow"
                    tabId="2"
                  >
                    <img
                      src={work2}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white p-4  show rounded shadow"
                    tabId="3"
                  >
                    <img
                      src={work3}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>

                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="4"
                  >
                    <img
                      src={work4}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="5"
                  >
                    <img
                      src={work3}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                  <TabPane
                    className="fade bg-white show p-4 rounded shadow"
                    tabId="6"
                  >
                    <img
                      src={work1}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                    <div className="mt-4">
                      <p className="text-muted">
                        This is required when, for example, the final text is
                        not yet available. Dummy text is also known as
                        &apos;fill text&apos;. It is said that song composers of
                        the past used dummy texts as lyrics.
                      </p>
                      <Link
                        to="/portfolio-detail-four"
                        className="text-primary"
                      >
                        See More{" "}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container> */}
          <Container>
            <Row className="align-items-end mb-4 pb-4">
              <SectionTitle
                title="Research & Experiments"
                desc="Exploring the frontier of technology to innovate and solve tomorrow's challenges today. Our continuous research and experiments drive cutting-edge solutions for your success."
              />
            </Row>
            <Row>
              <Col md={4} className="mt-4 pt-2">
                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                  {researchExperiments.map((experiment) => (
                    <NavItem key={experiment.id} className="mt-2">
                      <NavLink
                        to="#"
                        className={classnames(
                          { active: activeTab === experiment.id },
                          "rounded",
                        )}
                        onClick={() => this.toggle(experiment.id)}
                      >
                        <div className="text-center py-1">
                          <h6 className="mb-0">{experiment.title}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                  ))}
                </ul>
              </Col>

              <Col md={8} xs={12} className="mt-4 pt-2">
                <TabContent activeTab={activeTab}>
                  {researchExperiments.map((experiment) => (
                    <TabPane
                      key={experiment.id}
                      className="fade bg-white show p-4 rounded shadow"
                      tabId={experiment.id}
                    >
                      <img
                        src={experiment.image}
                        className="img-fluid rounded shadow"
                        alt=""
                      />
                      <div className="mt-4">
                        <p className="text-muted">{experiment.description}</p>
                        <Link to={experiment.link} className="text-primary">
                          See More{" "}
                          <i className="uil uil-angle-right-b align-middle"></i>
                        </Link>
                      </div>
                    </TabPane>
                  ))}
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
        <ExperienceTeam />
      </React.Fragment>
    );
  }
}
