import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import work14 from "../../assets/images/work/bs-o.jpeg";
import work15 from "../../assets/images/work/ev-b.jpeg";
import work16 from "../../assets/images/work/bt-5.jpeg";
import work17 from "../../assets/images/work/mx.jpeg";
import { Link } from "react-router-dom";

class StartupStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      blogsDetails: [
        {
          id: 1,
          title: "BotSupply APS: Aquired by XtendOPS USA",
          image: work14,
          desc: "An ORACLE Partner company, won several awards for their innovations in conversational AI.",
          like: "33",
          comment: "08",
          autor: "",
          date: "13th August, 2019",
          tags: ["Web Development", "Trends", "Technology"],
          summary:
            "An ORACLE Partner company, won several awards for their innovations in conversational AI.",
          content: [
            {
              type: "paragraph",
              title: "Introduction:",
              text: "Botsupply APS, a Denmark-based company, began its journey with a vision to revolutionize the way businesses interact with their customers through conversational AI. With a small but dedicated team, Botsupply quickly established itself as a leader in the chatbot and AI industry, helping businesses automate customer service and enhance user engagement.",
              items: [],
            },

            {
              type: "list",
              items: [
                {
                  boldText: "",
                  normalText:
                    "The founders of Botsupply recognized a growing need in the market—companies were struggling to provide instant and efficient customer support as their businesses scaled. Traditional customer service models were proving costly and inefficient, with long wait times and inconsistent responses frustrating customers. Botsupply set out to solve this problem by creating AI-powered chatbots that could provide real-time, accurate, and consistent customer support.",
                },
              ],
              parentTitle: "The Problem:",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Solution",
              items: [],
              text: "Botsupply developed a suite of AI-driven chatbot solutions that could be easily integrated into existing business platforms. These chatbots were designed to handle various customer queries, automate repetitive tasks, and provide personalized interactions. The company focused on creating user-friendly interfaces and ensuring that their chatbots could be customized to meet the unique needs of each client.",
            },
            {
              type: "paragraph",
              title: "Our Collaboration:",
              items: [
                {
                  boldText: "",
                  normalText:
                    "Botsupply approached us, iAastha, for our expertise in software development and AI integration. Together, we worked on refining their product and scaling it for a global market. Our team provided end-to-end engineering support, ensuring that Botsupply’s technology could handle increasing demand while maintaining top-notch performance.",
                },
                {
                  boldText: "",
                  normalText:
                    "We also assisted Botsupply in winning prestigious awards, including recognition from IBM and Oracle. Additionally, we supported their efforts in securing grants from the Danish government, which further fueled their growth and innovation.",
                },
              ],
            },
            {
              type: "paragraph",
              title: "Achievements:",
              items: [
                {
                  boldText: "",
                  normalText:
                    "Botsupply’s success story is a testament to the power of innovation and collaboration. The company went on to win multiple accolades, including IBM and Oracle awards, solidifying its reputation as a leader in the conversational AI space. The Danish government’s grant further validated their business model and technology.",
                },
                {
                  boldText: "",
                  normalText:
                    "Botsupply’s success attracted the attention of international players, leading to its acquisition by XtendOps, a U.S.-based company. This acquisition marked a significant milestone in Botsupply’s journey, enabling them to expand their reach and continue their mission on a larger scale.",
                },
              ],
            },
            {
              type: "paragraph",
              title: "Conclusion:",
              items: [
                {
                  boldText: "",
                  normalText:
                    "From a small startup in Denmark to a globally recognized player in conversational AI, Botsupply APS’s journey is one of determination, innovation, and strategic partnerships. We at iAastha are proud to have played a role in their success, and we look forward to continuing to support startups that aim to change the world with cutting-edge technology.",
                },
              ],
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Industry Focus: ",
                  normalText: "Conversational AI and Chatbots",
                },
                {
                  boldText: "Milestones: ",
                  normalText:
                    "IBM and Oracle Awards, Danish Government Grants and Finally Acquisition by XtendOps USA",
                },
                {
                  boldText: "Collaboration: ",
                  normalText:
                    "Worked with iAastha for software development and AI integration\n",
                },
                {
                  boldText: "Outcome: ",
                  normalText:
                    "Product that was made scalable and global, won awards and grants and finally got acquired by XtendOps USA",
                },
              ],
              parentTitle: "Key Takeaways:",
              subTitle: "",
            },
            {
              type: "paragraph",
              title: "",
              text: "Botsupply’s journey serves as an inspiration to startups worldwide, proving that with the right vision and support, even the smallest companies can achieve remarkable success.",
            },
          ],
        },

        {
          id: 2,
          title: "Moxci Premiers: Movie Premiere Experience Redefined",
          image: work17,
          desc: "In the complex and often unpredictable world of maritime shipping, our client—a forward-thinking startup—set out to transform how operational tasks are handled on the high seas. Focused on automating manual operations, particularly those related to the Bill of Lading and other critical operational issues, this startup aimed to alleviate the burdens faced by sailors and traders as they navigate the challenges of transporting goods across oceans.",
          like: "33",
          comment: "08",
          autor: "",
          date: "13th August, 2019",
          summary:
            "In the complex and often unpredictable world of maritime shipping, our client—a forward-thinking startup—set out to transform how operational tasks are handled on the high seas. Focused on automating manual operations, particularly those related to the Bill of Lading and other critical operational issues, this startup aimed to alleviate the burdens faced by sailors and traders as they navigate the challenges of transporting goods across oceans.",
          content: [
            {
              type: "paragraph",
              title: "",
              text: "In the complex and often unpredictable world of maritime shipping, our client—a forward-thinking startup—set out to transform how operational tasks are handled on the high seas. Focused on automating manual operations, particularly those related to the Bill of Lading and other critical operational issues, this startup aimed to alleviate the burdens faced by sailors and traders as they navigate the challenges of transporting goods across oceans.",
              items: [],
            },
            {
              type: "paragraph",
              title: "Challenge",
              text: "",
              items: [],
            },

            {
              type: "list",
              items: [
                {
                  boldText: "Inherent Complexity of Maritime Operations:",
                  normalText:
                    "Shipping goods across international waters involves numerous stakeholders, detailed documentation, and precise coordination. The manual handling of these tasks was both time-consuming and error-prone.",
                },
                {
                  boldText: "Data Integration from Multiple Sources:",
                  normalText:
                    "The client needed to pull and process data from various sources, including real-time streaming data and one-time historical data dumps, to create a comprehensive and reliable operational workflow.",
                },
                {
                  boldText: "Data Security and Compliance:",
                  normalText:
                    "Managing sensitive information such as Personally Identifiable Information (PII) while adhering to global regulations like GDPR was a major concern, particularly in an industry as sensitive as shipping.",
                },
                {
                  boldText:
                    "Model Training and Optimization for Operational Tasks:",
                  normalText:
                    "Deploying AI models that could accurately assist in making operational decisions, optimize workflows, and anticipate challenges required a sophisticated approach to model training and deployment.",
                },
                {
                  boldText: "Real-Time Decision Making:",
                  normalText:
                    "For sailors and traders, timely decisions can make or break a shipment's success. The solution needed to provide real-time insights and facilitate immediate action.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Solution",
              items: [],
              text: "Leveraging our intimate understanding of the shipping industry's complexities and our passion for AI-driven industry transformation, we crafted a bespoke solution that integrated AI into the client’s operational workflows, addressing their unique challenges head-on.",
            },
            {
              type: "paragraph",
              title: "1. Data Ingestion and Cleansing",
              items: [
                {
                  boldText: "Data Ingestion:",
                  normalText:
                    "We set up a robust data ingestion framework that could handle both real-time streaming data from External APIs and one-time data dumps. This ensured that all relevant information was captured, from ship movement data to transactional logs.",
                },
                {
                  boldText: "Data Cleansing and Transformation:",
                  normalText:
                    "Given the critical nature of maritime operations, the data was meticulously cleansed and transformed. With the support of AWS Macie, the solution identified and secured sensitive information, ensuring compliance with global data security standards.",
                },
              ],
            },
            {
              type: "paragraph",
              title: "2. Feature Engineering and Model Training",
              items: [
                {
                  boldText: "Data Wrangling and Feature Engineering:",
                  normalText:
                    "We enhanced the data by implementing custom transformation layers, named entity recognition (NER), and dimensionality reduction techniques. This process ensured that the data was not only clean but also structured in a way that made it actionable for AI-driven insights.",
                },
                {
                  boldText: "AI Model Training and Optimization:",
                  normalText:
                    "Using Amazon SageMaker, we developed and optimized machine learning models tailored to the unique needs of the shipping industry. These models were rigorously trained, with hyperparameter tuning and algorithm optimization, to assist in operational decision-making and automate routine tasks.",
                },
              ],
            },
            {
              type: "paragraph",
              title: "3. Deployment and Real-Time Inference",
              items: [
                {
                  boldText: "Model Deployment and Inference:",
                  normalText:
                    "The trained models were deployed into the client’s environment using a sophisticated deployment pipeline that included vector storage and a mapping database. This setup allowed the models to be quickly accessed and utilized for real-time decision-making via an Inference Layer that exposed an API for usage.",
                },
                {
                  boldText: "Frontend Dashboard for Real-Time Monitoring:",
                  normalText:
                    "A user-friendly Frontend Dashboard was developed, enabling sailors, traders, and other stakeholders to monitor operations in real-time. This dashboard provided critical insights, allowing users to make informed decisions and quickly respond to any operational issues.",
                },
              ],
            },
            {
              type: "paragraph",
              title: "Results",
              text: "",
              items: [],
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Seamless Automation of Operational Tasks:",
                  normalText:
                    "By automating key operational tasks, the client was able to significantly reduce the manual workload on sailors and traders, allowing them to focus on more strategic aspects of their roles.",
                },
                {
                  boldText: "Enhanced Decision-Making Capabilities:",
                  normalText:
                    "The real-time data processing and AI-driven insights provided by the solution enabled the client to make faster, more informed decisions, ultimately improving the efficiency and reliability of their shipping operations.",
                },
                {
                  boldText: "Improved Compliance and Data Security:",
                  normalText:
                    "The integration of AWS Macie and other security features ensured that all data handling processes were fully compliant with GDPR and other international regulations, protecting sensitive information from unauthorized access.",
                },
                {
                  boldText: "Scalable and Adaptable Solution:",
                  normalText:
                    "The AI models and deployment pipeline were designed to scale with the client’s growing needs, making it easy to add new data sources, adapt to new operational requirements, and handle increasing data volumes.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },
            {
              type: "paragraph",
              title: "Conclusion",
              items: [
                {
                  boldText: "",
                  normalText:
                    "Having experienced the challenges of the maritime industry firsthand, we brought our expertise to bear in creating a solution that not only addressed the client’s immediate operational needs but also laid the foundation for long-term growth and efficiency. By embedding bespoke AI assistants into the client’s workflows, we helped them weather the operational storms of the shipping industry and emerge stronger, more efficient, and better equipped to navigate the complexities of global trade.",
                },
              ],
            },
          ],
          tags: ["Web Development", "Trends", "Technology"],
        },
        {
          id: 3,
          title: "BillTrim: Transforming FinTech with Automated Bill Management and Strategic Growth",
          image: work16,
          desc: "",
          like: "33",
          comment: "08",
          autor: "",
          date: "13th August, 2019",
          summary:
            "Jatana is a cutting-edge customer service automation platform that revolutionizes the way businesses handle support requests. It is designed to integrate seamlessly with popular customer service and CRM systems, with its current integration being with Zendesk. By creating a unique AI engine for each client, Jatana leverages historical data to suggest replies, automate responses, tag and triage tickets, and provide detailed metrics. Being language-agnostic, Jatana is ideal for global teams that offer support in multiple languages. Notably, Jatana was selected to be part of Station F in Paris, one of the world’s largest startup campuses, further validating its innovative approach to customer service automation.",
          content: [
            {
              type: "paragraph",
              title: "Project Objective",
              text: "The client sought to enhance their platform's capabilities and reach by integrating it with Zendesk and expanding their services to B2B clients. This required not only the development of a robust engineering solution but also the migration of vast amounts of historical data from various customer service systems to ensure seamless operation and training of the AI engine.Our Role We were entrusted with the complete engineering of Jatana's platform, specifically focusing on the following key areas:",
              items: [],
            },
            {
              type: "paragraph",
              title: "Our Role",
              text: "We were entrusted with the complete engineering of Jatana's platform, specifically focusing on the following key areas:",
              items: [],
            },

            {
              type: "list",
              items: [
                {
                  boldText: "Zendesk Integration:",
                  normalText:
                    "We developed a comprehensive Zendesk extension, enabling Jatana to integrate smoothly with Zendesk and providing enhanced functionality for customer service automation.",
                },
                {
                  boldText: "Data Migration Services:",
                  normalText:
                    "Our team handled the intricate process of data migration for B2B clients. This involved transferring large volumes of historical data from existing customer service systems to Jatana, ensuring that the AI engines were trained with accurate and relevant information.",
                },
                {
                  boldText: "Engineering and Development:",
                  normalText:
                    "We were responsible for the overall engineering of the platform, ensuring that it was scalable, secure, and capable of handling complex customer service automation tasks.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Challenges",
              items: [],
              text: "",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Data Complexity:",
                  normalText:
                    "Migrating data from various customer service systems posed significant challenges due to differences in data structures and formats.",
                },
                {
                  boldText: "Scalability:",
                  normalText:
                    "Ensuring that the platform could handle a high volume of tickets and customer interactions without compromising performance.",
                },
                {
                  boldText: "Customization:",
                  normalText:
                    "Creating an AI engine tailored to each client’s specific needs, requiring flexible and adaptive engineering solutions.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },
            {
              type: "image",
              url: "",
              caption: "",
            },
            {
              type: "paragraph",
              title: "Solution and Implementation",
              items: [],
              text: "Our approach to overcoming these challenges included:",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Custom Data Migration Tools:",
                  normalText:
                    "We developed custom tools to automate the data migration process, ensuring accuracy and minimizing downtime.",
                },
                {
                  boldText: "Scalable Architecture:",
                  normalText:
                    "We designed the platform architecture to be highly scalable, allowing it to handle increasing loads as Jatana expanded its client base.",
                },
                {
                  boldText: "Flexible AI Engine:",
                  normalText:
                    "By working closely with the Jatana team, we ensured that the AI engine was flexible enough to be trained on diverse datasets and deliver accurate results across different languages and support scenarios.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },
            {
              type: "paragraph",
              title: "Results",
              items: [],
              text: "",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Increased Efficiency:",
                  normalText:
                    "The integration with Zendesk enabled Jatana to automate up to 50% of ticket responses, significantly reducing the workload on support agents.",
                  link: "https://www.zendesk.com/in/marketplace/partners/1279/jatana",
                },
                {
                  boldText: "Enhanced Analytics:",
                  normalText:
                    "The platform's ability to provide detailed metrics allowed clients to gain deeper insights into their customer service operations.",
                },
                {
                  boldText: "Successful Data Migration:",
                  normalText:
                    "We successfully migrated vast amounts of data without any loss, ensuring that the AI engines were trained effectively.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Conclusion",
              items: [
                {
                  boldText: "",
                  normalText:
                    "Our collaboration with Jatana resulted in a robust and scalable customer service automation platform that is now ready to integrate with additional customer service and CRM systems. By handling the complete engineering and data migration processes, we enabled Jatana to offer a powerful solution to its B2B clients, helping them deliver faster, more efficient customer service. This project showcases our ability to deliver complex engineering solutions and manage large-scale data migration tasks, providing our clients with the tools they need to succeed in an increasingly automated world.",
                },
              ],
            },
          ],
          tags: ["Web Development", "Trends", "Technology"],
        },
        {
          id: 4,
          title: "Design your apps in your own way",
          image: work15,
          desc: "Due to its widespread use as filler text for layouts, non-readability",
          like: "33",
          comment: "08",
          autor: "",
          date: "13th August, 2019",
          summary:
            "Atmos, India's first affordable PM2.5 monitor, is at the forefront of environmental science, offering free data and a user-friendly dashboard. This innovative device provides accurate, real-time air quality monitoring, making critical environmental data accessible to a broader audience. In addition to measuring PM2.5 levels, Atmos also tracks other pollutants, enabling users to understand the air quality in their surroundings. The initiative aims to raise awareness about air pollution and empower communities to take informed actions to protect their health.",
          content: [
            {
              type: "paragraph",
              title: "Project Objective",
              text: "The client sought to increase the efficiency and scalability of their data ingestion process from IoT devices deployed across various locations. Additionally, they aimed to enhance data quality and expand their data coverage by integrating supplementary data from government websites, particularly in areas where IoT devices were not installed.",
              items: [],
              link: "https://atmos.urbansciences.in/atmos/maps",
            },
            {
              type: "paragraph",
              title: "Our Role",
              text: "We were responsible for two key areas in this project:",
              items: [],
            },

            {
              type: "list",
              items: [
                {
                  boldText: "Increasing Throughput of Data Ingestion:",
                  normalText:
                    "We optimized the data ingestion pipeline from Atmos's IoT devices to their servers. This involved enhancing the system's capacity to process and store large volumes of data generated by the devices in real time, ensuring the continuous availability of accurate and up-to-date air quality information.",
                },
                {
                  boldText: "Data Extraction from Government Websites:",
                  normalText:
                    "To improve data quality and coverage, we worked with the Atmos team to extract relevant environmental data from government websites. This data served as a supplement to the IoT-generated data, particularly in regions where IoT devices were not deployed, thus providing a more comprehensive picture of air quality across various locations.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Challenges",
              items: [],
              text: "",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Scalability:",
                  normalText:
                    "As the number of IoT devices increased, the existing infrastructure struggled to keep up with the data volume. Optimizing the data pipeline to handle this growth was crucial.",
                },
                {
                  boldText: "Data Integration:",
                  normalText:
                    "Extracting and integrating data from government sources presented challenges due to differences in data formats and the need for real-time updates.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Solution and Implementation",
              items: [],
              text: "Our approach to overcoming these challenges included:",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Optimized Data Ingestion Pipeline:",
                  normalText:
                    "We implemented a robust, scalable architecture that could handle increased data throughput from IoT devices. This included optimizing the data flow from devices to servers and ensuring that the system could process and store the data efficiently.",
                },
                {
                  boldText: "Automated Data Extraction Tools:",
                  normalText:
                    "We developed automated tools for extracting data from government websites, standardizing the data formats, and integrating it with the IoT data. This approach not only improved data quality but also expanded coverage to areas where IoT devices were absent.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },
            {
              type: "paragraph",
              title: "Results",
              items: [],
              text: "",
            },
            {
              type: "list",
              items: [
                {
                  boldText: "Improved Data Processing:",
                  normalText:
                    "The optimized data ingestion pipeline significantly increased the system’s ability to handle larger volumes of data, ensuring real-time availability of accurate air quality information.",
                },
                {
                  boldText: "Expanded Data Coverage:",
                  normalText:
                    "By integrating data from government sources, Atmos was able to provide a more comprehensive view of air quality, even in regions without IoT device installations.",
                },
                {
                  boldText: "Enhanced Data Quality:",
                  normalText:
                    "The combination of IoT-generated data and government-sourced data resulted in higher data accuracy and reliability, empowering users to make better-informed decisions about their environment.",
                },
              ],
              parentTitle: "",
              subTitle: "",
            },

            {
              type: "paragraph",
              title: "Conclusion",
              items: [
                {
                  boldText: "",
                  normalText:
                    "Our collaboration with Atmos resulted in a more efficient and scalable air quality monitoring system that leverages both IoT and government data sources. By increasing the throughput of data ingestion and expanding data coverage, we helped Atmos deliver a more comprehensive and reliable air quality monitoring solution to its users. This project underscores our expertise in IoT, data integration, and environmental monitoring, contributing to the success of a vital initiative in raising awareness about air pollution in India. This case study highlights our ability to deliver innovative and scalable solutions in the IoT and environmental science domains, helping clients like Atmos lead the way in making critical environmental data accessible to all.",
                },
              ],
            },
          ],
          tags: ["Web Development", "Trends", "Technology"],
        },
      ],
    };
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    // JSON data defined here
    const blogs = [
      {
        id: 1,
        title: "BotSupply APS: Aquired by XtendOPS USA",
        image: work14,
        desc: "An ORACLE Partner company, won several awards for their innovations in conversational AI.",
        like: "33",
        comment: "08",
        autor: "",
        date: "13th August, 2019",
        tags: ["Web Development", "Trends", "Technology"],
        summary:
          "An ORACLE Partner company, won several awards for their innovations in conversational AI.",
        content: [
          {
            type: "paragraph",
            title: "Introduction:",
            text: "Botsupply APS, a Denmark-based company, began its journey with a vision to revolutionize the way businesses interact with their customers through conversational AI. With a small but dedicated team, Botsupply quickly established itself as a leader in the chatbot and AI industry, helping businesses automate customer service and enhance user engagement.",
            items: [],
          },

          {
            type: "list",
            items: [
              {
                boldText: "",
                normalText:
                  "The founders of Botsupply recognized a growing need in the market—companies were struggling to provide instant and efficient customer support as their businesses scaled. Traditional customer service models were proving costly and inefficient, with long wait times and inconsistent responses frustrating customers. Botsupply set out to solve this problem by creating AI-powered chatbots that could provide real-time, accurate, and consistent customer support.",
              },
            ],
            parentTitle: "The Problem:",
            subTitle: "",
          },

          {
            type: "paragraph",
            title: "Solution",
            items: [],
            text: "Botsupply developed a suite of AI-driven chatbot solutions that could be easily integrated into existing business platforms. These chatbots were designed to handle various customer queries, automate repetitive tasks, and provide personalized interactions. The company focused on creating user-friendly interfaces and ensuring that their chatbots could be customized to meet the unique needs of each client.",
          },
          {
            type: "paragraph",
            title: "Our Collaboration:",
            items: [
              {
                boldText: "",
                normalText:
                  "Botsupply approached us, iAastha, for our expertise in software development and AI integration. Together, we worked on refining their product and scaling it for a global market. Our team provided end-to-end engineering support, ensuring that Botsupply’s technology could handle increasing demand while maintaining top-notch performance.",
              },
              {
                boldText: "",
                normalText:
                  "We also assisted Botsupply in winning prestigious awards, including recognition from IBM and Oracle. Additionally, we supported their efforts in securing grants from the Danish government, which further fueled their growth and innovation.",
              },
            ],
          },
          {
            type: "paragraph",
            title: "Achievements:",
            items: [
              {
                boldText: "",
                normalText:
                  "Botsupply’s success story is a testament to the power of innovation and collaboration. The company went on to win multiple accolades, including IBM and Oracle awards, solidifying its reputation as a leader in the conversational AI space. The Danish government’s grant further validated their business model and technology.",
              },
              {
                boldText: "",
                normalText:
                  "Botsupply’s success attracted the attention of international players, leading to its acquisition by XtendOps, a U.S.-based company. This acquisition marked a significant milestone in Botsupply’s journey, enabling them to expand their reach and continue their mission on a larger scale.",
              },
            ],
          },
          {
            type: "paragraph",
            title: "Conclusion:",
            items: [
              {
                boldText: "",
                normalText:
                  "From a small startup in Denmark to a globally recognized player in conversational AI, Botsupply APS’s journey is one of determination, innovation, and strategic partnerships. We at iAastha are proud to have played a role in their success, and we look forward to continuing to support startups that aim to change the world with cutting-edge technology.",
              },
            ],
          },
          {
            type: "list",
            items: [
              {
                boldText: "Industry Focus: ",
                normalText: "Conversational AI and Chatbots",
              },
              {
                boldText: "Milestones: ",
                normalText:
                  "IBM and Oracle Awards, Danish Government Grants and Finally Acquisition by XtendOps USA",
              },
              {
                boldText: "Collaboration: ",
                normalText:
                  "Worked with iAastha for software development and AI integration\n",
              },
              {
                boldText: "Outcome: ",
                normalText:
                  "Product that was made scalable and global, won awards and grants and finally got acquired by XtendOps USA",
              },
            ],
            parentTitle: "Key Takeaways:",
            subTitle: "",
          },
          {
            type: "paragraph",
            title: "",
            text: "Botsupply’s journey serves as an inspiration to startups worldwide, proving that with the right vision and support, even the smallest companies can achieve remarkable success.",
          },
        ],
      },

      {
        id: 2,
        title: "Moxci Premiers: Movie Premiere Experience Redefined",
        image: work17,
        desc: "Revolutionizing the premiere experience",
        like: "33",
        comment: "08",
        autor: "",
        date: "13th August, 2019",
        summary:
          "Moxci Premiere is an innovative video streaming platform designed to bring people together in a time of social distancing and lockdowns. Founded by seasoned industry professionals, including a distributor and producer with Universal Studios in Los Angeles, USA, Moxci Premiere was born out of necessity during the COVID-19 pandemic. The platform revolutionized the way movies were premiered and reviewed, allowing users to create virtual rooms or theaters where they could watch videos and movies with friends, colleagues, or industry professionals, all while staying connected through real-time communication.",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "Moxci is an innovative video streaming platform designed to bring people together in a time of social distancing and lockdowns. Founded by seasoned industry professionals, including a distributor and producer with Universal Studios in Los Angeles, USA, Moxci Premiere was born out of necessity during the COVID-19 pandemic. The platform revolutionized the way movies were premiered and reviewed, allowing users to create virtual rooms or theaters where they could watch videos and movies with friends, colleagues, or industry professionals, all while staying connected through real-time communication.",
            items: [
            ],
          },
          {
            type: "paragraph",
            title: "Challenge",
            text: "During the COVID-19 lockdown, traditional movie premieres were halted, causing significant disruptions in the film industry. Producers and distributors faced challenges in showcasing their films to review panels and audiences. The pandemic also hindered the ability to gather feedback from moviegoers and industry insiders, which is critical during the final stages of production.",
            items: [
              {
                normalText: "This created a pressing need for a solution that could replicate the experience of a movie premiere while adhering to the constraints of social distancing."
              }
            ],
          },

          {
            type: "paragraph",
            title: "Solution",
            text: "Moxci Premiere stepped in to solve this problem with an innovative approach to video streaming. By leveraging AWS Elemental, we developed a platform that allowed users to create virtual rooms or theaters where movies could be premiered remotely. In these virtual theaters, participants could watch the movie together in real-time, regardless of their physical location.",
            items: [{
              normalText: "One of the platform’s most groundbreaking features was the ability for users to communicate in parallel with the movie screening. This enabled reviewers, producers, and distributors to discuss scenes, provide feedback, and share their thoughts instantly. The platform recreated the collaborative atmosphere of traditional premieres, allowing movie panels to deliver real-time insights and feedback, making it a perfect tool for film professionals during the pandemic."
              }],
             },
          {
            type: "paragraph",
            title: "Our Collaboration:",
            text: "We at iAastha played a pivotal role in bringing Moxci Premiere to life. Our team utilized AWS Elemental to build a robust and scalable platform that could handle high-quality video streaming, ensuring a seamless viewing experience. We designed the virtual room functionality to replicate the communal feel of a theater while incorporating communication tools that facilitated discussion among viewers.",
            items: [{
              normalText: "Our technical expertise ensured that Moxci Premiere could serve its purpose effectively, providing a secure, reliable, and high-performance platform that catered to the unique needs of the film industry during an unprecedented time."
            }],
          },
          {
            type: "paragraph",
            title: "Conclusion:",
            text: "Moxci Premiere's story is a testament to the power of innovation and adaptability in times of crisis. By addressing a critical need in the film industry during the COVID-19 pandemic, Moxci Premiere transformed the traditional movie premiere experience into a virtual event that maintained the same level of collaboration and interaction.",
            items: [{
              normalText: "At iAastha, we are proud to have been part of this journey, helping Moxci Premiere make a significant impact in the entertainment industry. The platform's success is a reminder that even the most challenging problems can be solved with creativity, technology, and the right partnerships."
            }],
          },
          {
            type: "list",
            parentTitle: "Key Takeaways:",
            subTitle: "",
            items: [
              {
                boldText: "Industry Focus:",
                normalText:
                  "Video Streaming and Movie Premieres",
              },
              {
                boldText: "Technology: ",
                normalText:
                  "Built on AWS Elemental",
              },
              {
                boldText: "Collaboration: ",
                normalText:
                  "iAastha provided technical expertise in platform development",
              },
              {
                boldText: "Outcome: ",
                normalText:
                  "Enabled remote movie premieres and real-time communication during the pandemic, with long-term potential for global remote premieres",
              },
            ],
          },
        ],
        tags: ["Web Development", "Trends", "Technology"],
      },
      {
        id: 3,
        title: "BillTrim Inc.: Transforming FinTech with Automated Bill Management",
        image: work16,
        desc: "Revolutionizing the way people manage their bills",
        like: "33",
        comment: "08",
        autor: "",
        date: "13th August, 2019",
        summary:
            "",
        content: [
          {
            type: "paragraph",
            title: "",
            text: "BillTrim Inc., a California-based startup, was founded with a mission to help consumers save money effortlessly by automating bill negotiation and payment processes. Recognizing the widespread frustration of overpaying for services like cable, internet, and utilities, BillTrim set out to make savings and bill management accessible to everyone. With their FinTech platform designed for automated bill payments, coupled with expert negotiation services, BillTrim quickly became a go-to solution for consumers looking to reduce their monthly expenses without the hassle of manual negotiations and payments.",
            items: [],
          },
          {
            type: "paragraph",
            title: "The Problem:",
            text: "Many consumers unknowingly overpay for essential services due to complex pricing structures and rising costs. Negotiating with service providers and managing bill payments manually can be time-consuming and stressful. Most people simply don’t have the time or expertise to secure the best rates or stay on top of payments, leading to unnecessary financial strain. This created a significant opportunity in the market for a service that could automate both the negotiation and payment processes, ensuring savings and timely payments for consumers.",
            items: [],
          },

          {
            type: "list",
            parentTitle: "The Solution:",
            subTitle: "",
            items: [
              {
                boldText: "",
                normalText:
                    "BillTrim Inc. offered a comprehensive solution: an automated bill negotiation and payment platform that handled everything from start to finish. Customers simply uploaded their bills to the BillTrim platform, and the company’s proprietary technology and expert negotiators took care of securing lower rates. In addition, BillTrim’s platform automated bill payments, ensuring that customers never missed a payment deadline, further enhancing financial management and convenience.",
              },
              {
                boldText: "",
                normalText:
                    "The platform's user-friendly interface and effective results made it an attractive option for busy consumers who wanted to reduce their bills and manage payments effortlessly.",
              },

            ],
          },

          {
            type: "paragraph",
            title: "Our Collaboration:",
            items: [
              {
                normalText: "At iAastha, we had the privilege of partnering with BillTrim Inc. during their growth phase. Our team provided essential technical and engineering support, helping to scale the platform to accommodate a growing user base. We optimized their automated systems, ensuring that both the negotiation and payment processes were seamless and efficient. Additionally, we assisted with data migration services, enabling BillTrim to handle an increasing volume of bills and transactions as their customer base expanded."
              },
              {
                normalText: "Our collaboration also extended to developing secure and scalable infrastructure, allowing BillTrim to focus on their core mission of saving customers money and automating bill payments while we managed the technical complexities behind the scenes."
              }
            ],
            text: "",
          },
          {
            type: "paragraph",
            title: "Achievements:",
            items: [
              {
                normalText: "BillTrim’s success was rapid and impactful. The company helped thousands of customers save money on their bills, often achieving reductions of 20-30% or more. Their innovative approach caught the attention of both consumers and investors, leading to significant growth and market penetration."
              },
              {
                normalText: "One of the key milestones in BillTrim's journey was raising $1.5 million in seed funding, which fueled their expansion and enabled them to enhance their FinTech platform for automated bill payments. This funding round was a testament to the trust investors placed in BillTrim’s vision and potential."
              },
              {
                normalText: "Their success in negotiating a vast array of bills for various services, coupled with the automation of payments, translated into substantial collective savings and convenience for their users. The platform's reputation for reliability and effectiveness further solidified its standing in the industry, attracting more users seeking effortless savings and automated financial management."
              }
            ],
          },
          {
            type: "paragraph",
            title: "Conclusion:",
            items: [
              {
                normalText: "BillTrim Inc.’s journey from a startup to a trusted name in automated bill negotiation and payment services is a story of innovation, determination, and customer-centricity. By addressing a common problem and offering a comprehensive solution, BillTrim transformed the way consumers manage their bills, save money, and stay on top of payments."
              },
              {
                normalText: "We at iAastha are proud to have been part of BillTrim’s success story, providing the technical expertise and support needed to help them scale and thrive. The company’s achievements, including raising $1.5 million in seed funding, are a testament to the impact that technology can have on improving everyday financial lives, and we look forward to seeing BillTrim continue to lead the way in automated savings and payments."
              },
              {
                normalText: "Their success in negotiating a vast array of bills for various services, coupled with the automation of payments, translated into substantial collective savings and convenience for their users. The platform's reputation for reliability and effectiveness further solidified its standing in the industry, attracting more users seeking effortless savings and automated financial management."
              }
            ],
          },
          {
            type: "list",
            parentTitle: "Key Takeaways:",
            subTitle: "",
            items: [
              {
                boldText: "Industry Focus: ",
                normalText:
                    "Bill Negotiation, Automated Bill Payments, and FinTech",
              },
              {
                boldText: "Technology: ",
                normalText:
                    "Automated bill negotiation and payment platform",
              },
              {
                boldText: "Collaboration: ",
                normalText:
                    "iAastha provided engineering support, data migration services, and infrastructure optimization",
              },
              {
                boldText: "Funding: ",
                normalText:
                "Raised $1.5 million in seed funding",
              },
              {
                boldText: "Outcome: ",
                normalText:
                    "Significant savings for thousands of users, automated bill payments, rapid growth, and market leadership in FinTech for bill management",
              },
            ],
          },
          {
            type: "paragraph",
            title: "",
            text: "BillTrim Inc. exemplifies how startups can create meaningful change by solving everyday problems with innovative technology and a focus on customer satisfaction."

          },
        ],
        tags: ["Web Development", "Trends", "Technology"],
      },
      {
        id: 4,
        title: "Envi by Malbork.in: Pioneering Sustainable Transportation with Bangalore's First All-Electric Taxi Service",
        image: work15,
        desc: "",
        like: "33",
        comment: "08",
        autor: "",
        date: "",
        summary:
          "",
        content: [
          {
            type: "paragraph",
            title: "Introduction:",
            text: "Malbork.in, the company behind Envi, is paving the way for a greener future in Bangalore with their all-electric, app-based taxi service. As a B2C taxi aggregator, Envi's vision is to not only provide convenient transportation but also to combat climate change by reducing greenhouse gas emissions. With a recent license from the Karnataka State Transport Authority, Envi is set to transform the city's transportation landscape by running a fleet of electric vehicles powered by sustainable  energy.",
            items: [],
          },
          {
            type: "paragraph",
            title: "The Problem:",
            text: "Urban transportation has long been a major contributor to air pollution and greenhouse gas emissions, particularly in rapidly growing cities like Bangalore. Traditional fossil fuel-powered vehicles release harmful emissions, exacerbating environmental issues and impacting public health. As awareness of climate change grows, there is increasing demand for eco-friendly transportation solutions that can reduce the carbon footprint of daily commutes.",
            items: [],
          },
          {
            type: "paragraph",
            title: "The Solution:",
            text: "Malbork.in launched Envi to address this challenge head-on. By offering an all-electric taxi service, Envi provides a cleaner alternative to traditional fossil fuel-powered transportation. What sets Envi apart is their commitment to sustainability at every level of the business. Not only are their vehicles electric, but the company also powers its operations—including its offices and charging stations—entirely through electricity. This comprehensive approach ensures that Envi is truly green, reducing emissions both on the road and at the office.",
            items: [{
              normalText: "The app-based service makes it easy for Bangalore residents to book a ride, while knowing that they are contributing to a cleaner environment. Envi is designed to be a convenient, reliable, and eco-friendly transportation option that aligns with the growing demand for sustainability."
            }],
          },
          {
            type: "paragraph",
            title: "Our Collaboration:",
            text: "At iAastha, we were proud to partner with Malbork.in in their journey to launch Envi. Our team provided critical technical support, helping to develop and optimize the app platform to ensure a smooth and user-friendly experience for customers. We also assisted in integrating the necessary infrastructure to support the all-electric fleet, including sustainable energy solutions and real-time tracking features.",
            items: [
              {
                normalText: "Our collaboration with Malbork.in was driven by a shared commitment to sustainability and innovation. By leveraging our technical expertise, we helped Envi create a seamless and eco-friendly transportation service that is revolutionizing urban mobility in Bangalore."
              }
            ],
          },
          {
            type: "paragraph",
            title: "Achievements:",
            text: "Envi’s successful launch marks a significant step towards a more sustainable future for Bangalore. By securing the license to operate, Envi became one of the few all-electric taxi services in the city, setting a new standard for eco-friendly transportation.",
            items: [
              {
                normalText: "The company’s unique approach of powering both its vehicles solidified its position as a leader in green transportation. Envi has already started to make an impact by providing an eco-friendly alternative to traditional taxis, reducing emissions, and raising awareness about the importance of sustainable transportation."
              }
            ],
          },
          {
            type: "paragraph",
            title: "Conclusion",
            text: "Malbork.in's vision for Envi is more than just about offering rides—it's about creating a positive environmental impact and leading the charge towards a greener future. Through innovative solutions and a commitment to sustainability, Envi is transforming urban transportation in Bangalore.",
            items: [
              {
                boldText: "",
                normalText:
                  "At iAastha, we are thrilled to have been part of Malbork.in's success story, providing the technical expertise and support needed to bring their vision to life. As Envi continues to grow, we look forward to seeing them lead the way in sustainable transportation.",
              },
            ],
          },
          {
            type: "list",
            parentTitle: "Key Takeaways:",
            subTitle: "",
            items: [
              {
                boldText: "Industry Focus: ",
                normalText:
                    "All-Electric Taxi Service, Sustainable Transportation",
              },
              {
                boldText: "Technology: ",
                normalText:
                    "App-based platform, powered by electricity",
              },
              {
                boldText: "Collaboration: ",
                normalText:
                    "iAastha provided technical support and infrastructure integration",
              },
              {
                boldText: "Outcome: ",
                normalText:
                    "Licensed to operate in Bangalore, contributing to reduced emissions and promoting eco-friendly transportation",
              }
            ],
          },
          {
            type: "paragraph",
            title: "",
            text: "Envi by Malbork.in exemplifies how businesses can make a meaningful impact by prioritizing sustainability and innovation, leading the way towards a cleaner, greener future."

          },
        ],
        tags: ["Web Development", "Trends", "Technology"],
      },
      {
        id: 4,
        title: "Design your apps in your own way",
        image: work15,
        desc: "Due to its widespread use as filler text for layouts, non-readability",
        like: "33",
        comment: "08",
        autor: "",
        date: "13th August, 2019",
        summary:
          "Atmos, India's first affordable PM2.5 monitor, is at the forefront of environmental science, offering free data and a user-friendly dashboard. This innovative device provides accurate, real-time air quality monitoring, making critical environmental data accessible to a broader audience. In addition to measuring PM2.5 levels, Atmos also tracks other pollutants, enabling users to understand the air quality in their surroundings. The initiative aims to raise awareness about air pollution and empower communities to take informed actions to protect their health.",
        content: [
          {
            type: "paragraph",
            title: "Project Objective",
            text: "The client sought to increase the efficiency and scalability of their data ingestion process from IoT devices deployed across various locations. Additionally, they aimed to enhance data quality and expand their data coverage by integrating supplementary data from government websites, particularly in areas where IoT devices were not installed.",
            items: [],
            link: "https://atmos.urbansciences.in/atmos/maps",
          },
          {
            type: "paragraph",
            title: "Our Role",
            text: "We were responsible for two key areas in this project:",
            items: [],
          },

          {
            type: "list",
            items: [
              {
                boldText: "Increasing Throughput of Data Ingestion:",
                normalText:
                  "We optimized the data ingestion pipeline from Atmos's IoT devices to their servers. This involved enhancing the system's capacity to process and store large volumes of data generated by the devices in real time, ensuring the continuous availability of accurate and up-to-date air quality information.",
              },
              {
                boldText: "Data Extraction from Government Websites:",
                normalText:
                  "To improve data quality and coverage, we worked with the Atmos team to extract relevant environmental data from government websites. This data served as a supplement to the IoT-generated data, particularly in regions where IoT devices were not deployed, thus providing a more comprehensive picture of air quality across various locations.",
              },
            ],
            parentTitle: "",
            subTitle: "",
          },

          {
            type: "paragraph",
            title: "Challenges",
            items: [],
            text: "",
          },
          {
            type: "list",
            items: [
              {
                boldText: "Scalability:",
                normalText:
                  "As the number of IoT devices increased, the existing infrastructure struggled to keep up with the data volume. Optimizing the data pipeline to handle this growth was crucial.",
              },
              {
                boldText: "Data Integration:",
                normalText:
                  "Extracting and integrating data from government sources presented challenges due to differences in data formats and the need for real-time updates.",
              },
            ],
            parentTitle: "",
            subTitle: "",
          },

          {
            type: "paragraph",
            title: "Solution and Implementation",
            items: [],
            text: "Our approach to overcoming these challenges included:",
          },
          {
            type: "list",
            items: [
              {
                boldText: "Optimized Data Ingestion Pipeline:",
                normalText:
                  "We implemented a robust, scalable architecture that could handle increased data throughput from IoT devices. This included optimizing the data flow from devices to servers and ensuring that the system could process and store the data efficiently.",
              },
              {
                boldText: "Automated Data Extraction Tools:",
                normalText:
                  "We developed automated tools for extracting data from government websites, standardizing the data formats, and integrating it with the IoT data. This approach not only improved data quality but also expanded coverage to areas where IoT devices were absent.",
              },
            ],
            parentTitle: "",
            subTitle: "",
          },
          {
            type: "paragraph",
            title: "Results",
            items: [],
            text: "",
          },
          {
            type: "list",
            items: [
              {
                boldText: "Improved Data Processing:",
                normalText:
                  "The optimized data ingestion pipeline significantly increased the system’s ability to handle larger volumes of data, ensuring real-time availability of accurate air quality information.",
              },
              {
                boldText: "Expanded Data Coverage:",
                normalText:
                  "By integrating data from government sources, Atmos was able to provide a more comprehensive view of air quality, even in regions without IoT device installations.",
              },
              {
                boldText: "Enhanced Data Quality:",
                normalText:
                  "The combination of IoT-generated data and government-sourced data resulted in higher data accuracy and reliability, empowering users to make better-informed decisions about their environment.",
              },
            ],
            parentTitle: "",
            subTitle: "",
          },

          {
            type: "paragraph",
            title: "Conclusion",
            items: [
              {
                boldText: "",
                normalText:
                  "Our collaboration with Atmos resulted in a more efficient and scalable air quality monitoring system that leverages both IoT and government data sources. By increasing the throughput of data ingestion and expanding data coverage, we helped Atmos deliver a more comprehensive and reliable air quality monitoring solution to its users. This project underscores our expertise in IoT, data integration, and environmental monitoring, contributing to the success of a vital initiative in raising awareness about air pollution in India. This case study highlights our ability to deliver innovative and scalable solutions in the IoT and environmental science domains, helping clients like Atmos lead the way in making critical environmental data accessible to all.",
              },
            ],
          },
        ],
        tags: ["Web Development", "Trends", "Technology"],
      },
    ];

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const blogId = queryParams.get("id");

    if (blogId) {
      console.log(blogId, "blogId");

      // Find the corresponding blog from your data
      const blog = blogs.find((blog) => blog.id === parseInt(blogId));

      if (blog) {
        this.setState({ blog });
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    const { blog } = this.state;

    if (!blog) {
      return <p>Loading...</p>; // Show loading or error message if blog not found
    }
    return (
      <React.Fragment>
        <section className="bg-half">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <div className="section-title">
                  <div className="text-center">
                    <h4 className="title mb-4">{blog.title}</h4>
                    <img
                      src={blog.image}
                      className="img-fluid rounded-md shadow-md mb-2"
                      alt="#"
                    />
                  </div>
                  {/* Render content dynamically */}
                  {blog.content.map((item, index) => {
                    switch (item.type) {
                      case "paragraph":
                        return (
                          <div key={index}>
                            {item.title && (
                              <h5 className="pt-4">{item.title}</h5>
                            )}
                            <p className=" mb-0 mt-2">{item.text}</p>
                            {item.link && (
                              <Link
                                to={item.link}
                                target="_blank"
                                className="pt-6"
                              >
                                {item.link}
                              </Link>
                            )}
                            {item.items && item.items.length > 0 && (
                              <div>
                                {item.items.map((subItem, subIndex) => (
                                  <div key={subIndex} className="mt-2">
                                    {subItem.boldText && (
                                      <strong>{subItem.boldText}&nbsp;</strong>
                                    )}
                                    <span>{subItem.normalText}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      case "list":
                        return (
                          <div key={index}>
                            {item.parentTitle && (
                              <h5 className="mt-4">{item.parentTitle}</h5>
                            )}
                            {item.subTitle && <h6>{item.subTitle}</h6>}
                            <div className="">
                              {item.items &&
                                item.items.length > 0 &&
                                item.items.map((subItem, subIndex) => (
                                  <div key={subIndex} className="mt-2">
                                    {subItem.boldText && (
                                      <strong>{subItem.boldText}&nbsp;</strong>
                                    )}
                                    <span>{subItem.normalText}</span>
                                    {subItem.link && (
                                      <Link
                                        to={subItem.link}
                                        target="_blank"
                                        className="pt-6"
                                        style={{
                                          wordWrap: "break-word", // Allows long words to be broken and wrap to the next line
                                          whiteSpace: "normal", // Ensures the text wraps normally
                                          display: "inline-block", // Ensures the link behaves like an inline element but allows wrapping
                                          maxWidth: "100%", // Ensures the link does not exceed the width of its container
                                        }}
                                      >
                                        {subItem.link}
                                      </Link>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        );
                      case "image":
                        return (
                          item.url && (
                            <div key={index} className="text-center mt-4">
                              <img
                                src={item.url}
                                className="img-fluid rounded-md shadow-md"
                                alt={item.caption}
                              />
                              <p className=" pt-2">{item.caption}</p>
                            </div>
                          )
                        );
                      default:
                        return null;
                    }
                  })}

                  {/* <p className="text-muted mb-0 mt-4">
                    This is required when, for example, the final text is not
                    yet available. Dummy text is also known as 'fill text'. It
                    is said that song composers of the past used dummy texts as
                    lyrics when writing melodies in order to have a 'ready-made'
                    text to sing with the melody. Dummy texts have been in use
                    by typesetters since the 16th century.
                  </p>

                  <h4 className="my-4">Challenges</h4>
                  <p className="text-muted">
                    Due to its widespread use as filler text for layouts,
                    non-readability is of great importance: human perception is
                    tuned to recognize certain patterns and repetitions in
                    texts.
                  </p>
                  <p className="text-muted mb-0">
                    For this reason, dummy text usually consists of a more or
                    less random series of words or syllables. This prevents
                    repetitive patterns from impairing the overall visual
                    impression and facilitates the comparison of different
                    typefaces.
                  </p>

                  <h4 className="my-4">Solutions</h4>
                  <p className="text-muted">
                    Furthermore, it is advantageous when the dummy text is
                    relatively realistic so that the layout impression of the
                    final publication is not compromised.
                  </p>
                  <p className="text-muted mb-0">
                    One disadvantage of Lorum Ipsum is that in Latin certain
                    letters appear more frequently than others - which creates a
                    distinct visual impression. Moreover, in Latin only words at
                    the beginning of sentences are capitalized.
                  </p>

                  <h4 className="my-4">Results</h4>
                  <p className="text-muted">
                    Disadvantage of Lorum Ipsum is that in Latin certain letters
                    appear more frequently than others - which creates a
                    distinct visual impression. Moreover, in Latin only words at
                    the beginning of sentences are capitalized. It is
                    advantageous when the dummy text is relatively realistic so
                    that the layout impression of the final publication is not
                    compromised.
                  </p>

                  <Row>
                    <Col md={4} xs={6} className="mt-4 pt-2">
                      <div className="counter-box text-center">
                        <img
                          src={illustrator}
                          className="avatar avatar-small"
                          alt=""
                        />
                        <h2 className="mb-0 mt-4">
                          <span className="counter-value">
                            <CountUp start={3} end={15} duration={8} />
                          </span>
                          %
                        </h2>
                        <h6 className="counter-head text-muted">
                          Depritiation
                        </h6>
                      </div>
                    </Col>

                    <div className="col-md-4 col-6 mt-4 pt-2">
                      <div className="counter-box text-center">
                        <img
                          src={illustrator2}
                          className="avatar avatar-small"
                          alt=""
                        />
                        <h2 className="mb-0 mt-4">
                          <span className="counter-value" data-count="195">
                            <CountUp start={1} end={195} duration={8} />
                          </span>
                          %
                        </h2>
                        <h6 className="counter-head text-muted">Profit</h6>
                      </div>
                    </div>

                    <div className="col-md-4 col-6 mt-4 pt-2">
                      <div className="counter-box text-center">
                        <img
                          src={illustrator3}
                          className="avatar avatar-small"
                          alt=""
                        />
                        <h2 className="mb-0 mt-4">
                          <span className="counter-value" data-count="98">
                            <CountUp start={3} end={98} duration={8} />
                          </span>
                          %
                        </h2>
                        <h6 className="counter-head text-muted">Case Solved</h6>
                      </div>
                    </div>
                  </Row>

                  <h4 className="my-4">Client Feedback</h4>
                  <div className="p-4 bg-light">
                    <p className="text-muted h6 fst-italic">
                      " It seems that only fragments of the original text remain
                      in the Lorem Ipsum texts used today. The most well-known
                      dummy text is the 'Lorem Ipsum', which is said to have
                      originated in the 16th century. "
                    </p>
                    <img
                      src={client1}
                      className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <ul className="list-unstyled mb-0 mt-3">
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>{" "}
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>{" "}
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>{" "}
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>{" "}
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <h6 className="text-primary">
                      - Thomas Israel{" "}
                      <small className="text-muted">C.E.O</small>
                    </h6>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-3">Read More</h4>
                </div>
              </Col>
            </Row>

            <Row>
              {this.state.blogsDetails &&
                this.state.blogsDetails
                  .filter((blogs) => blogs.id !== blog.id)
                  .slice(0, 3) // Show only 3 blogs
                  .map((blog1, key) => (
                    // <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                    //   <Card
                    //     className=" border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden"
                    //     // style={{ height: "38rem" }}
                    //   >
                    //     <img
                    //       src={blog1.image}
                    //       className="img-fluid"
                    //       style={{ height: "14rem", width: "30rem" }}
                    //       alt="work"
                    //     />
                    //     <CardBody>
                    //       <div className="content">
                    //         <h5 className="mt-3">
                    //           <h5 className="text-dark title">
                    //             <a
                    //               href={`/startup-stories?id=${blog1.id}`}
                    //               className="text-dark"
                    //               onClick={() => window.location.reload()}
                    //             >
                    //               {blog1.title}
                    //             </a>
                    //           </h5>
                    //         </h5>

                    //         <a
                    //           href={`/startup-stories?id=${blog1.id}`}
                    //           className="text-primary readmore"
                    //           onClick={() => window.location.reload()}
                    //         >
                    //           Read More
                    //           <i className="uil uil-angle-right-b align-middle"></i>
                    //         </a>
                    //       </div>
                    //     </CardBody>
                    //   </Card>
                    // </Col>
                    <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                      <Card
                        className="border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden h-100"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div style={{ height: "14rem", overflow: "hidden" }}>
                          <img
                            src={blog1.image}
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="work"
                          />
                        </div>
                        <CardBody
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                          }}
                        >
                          <div className="content" style={{ flexGrow: 1 }}>
                            <h5 className="mt-3">
                              <h5 className="text-dark title">
                                <a
                                  href={`/startup-stories?id=${blog1.id}`}
                                  className="text-dark"
                                  onClick={() => window.location.reload()}
                                >
                                  {blog1.title}
                                </a>
                              </h5>
                            </h5>
                          </div>
                          <a
                            href={`/startup-stories?id=${blog1.id}`}
                            className="text-primary readmore mt-auto"
                            onClick={() => window.location.reload()}
                          >
                            Read More
                            <i className="uil uil-angle-right-b align-middle"></i>
                          </a>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default StartupStories;
