//import Images
import logistics01 from "../../assets/images/logistics/01.jpg";
import logistics02 from "../../assets/images/logistics/02.jpg";
import logistics03 from "../../assets/images/logistics/03.jpg";

import amazon from "../../assets/images/client/amazon.svg";
import google from "../../assets/images/client/google.svg";
import lenovo from "../../assets/images/client/lenovo.svg";
import paypal from "../../assets/images/client/paypal.svg";
import shopify from "../../assets/images/client/shopify.svg";
import spotify from "../../assets/images/client/spotify.svg";

import img1 from "../../assets/images/testimonial/fs.jfif";
import img2 from "../../assets/images/testimonial/vel.jpeg";
import img3 from "../../assets/images/testimonial/femi.jpeg";
import img4 from "../../assets/images/testimonial/ronak-sutaria.jpeg";
import img5 from "../../assets/images/testimonial/shridhar.jpg";
import img6 from "../../assets/images/client/06.jpg";

// Blog Images
import blog1 from "../../assets/images/logistics/blog01.jpg";
import blog2 from "../../assets/images/logistics/blog02.jpg";
import blog3 from "../../assets/images/logistics/blog03.jpg";
import blog01 from "../../assets/images/blog/01.jpg";
import blog02 from "../../assets/images/blog/02.jpg";
import blog03 from "../../assets/images/blog/03.jpg";

import user from "../../assets/images/icon/user.svg";
import calendar from "../../assets/images/icon/calendar.svg";
import sandClock from "../../assets/images/icon/sand-clock.svg";
import pen from "../../assets/images/icon/pen.svg";
import video from "../../assets/images/icon/video.svg";
import intellectual from "../../assets/images/icon/intellectual.svg";
import client1 from "../../assets/images/client/01.jpg";
import client2 from "../../assets/images/client/02.jpg";
import client3 from "../../assets/images/client/03.jpg";
import client4 from "../../assets/images/client/04.jpg";
import client5 from "../../assets/images/client/05.jpg";
import client6 from "../../assets/images/client/06.jpg";

import artDesign from "../../assets/images/icon/art-and-design.svg";
import smartphone from "../../assets/images/icon/smartphone.svg";
import clock from "../../assets/images/icon/clock.svg";
import stairs from "../../assets/images/icon/stairs.svg";
import code from "../../assets/images/icon/code.svg";
import device from "../../assets/images/icon/device.svg";
import Asset190 from "../../assets/images/illustrator/Asset190.svg";
import Asset189 from "../../assets/images/illustrator/Asset189.svg";
import Asset192 from "../../assets/images/illustrator/Asset192.svg";
import Asset187 from "../../assets/images/illustrator/Asset187.svg";

//import images
import hotel01 from "../../assets/images/hotel/01.jpg";
import hotel02 from "../../assets/images/hotel/02.jpg";
import hotel03 from "../../assets/images/hotel/03.jpg";
import hotel04 from "../../assets/images/hotel/04.jpg";
import hotel05 from "../../assets/images/hotel/05.jpg";
import hotel06 from "../../assets/images/hotel/06.jpg";
import hotel07 from "../../assets/images/hotel/07.jpg";
import hotel08 from "../../assets/images/hotel/08.jpg";
import hotel09 from "../../assets/images/hotel/09.jpg";
import hotelbg01 from "../../assets/images/hotel/bg01.jpg";
import hotelbg02 from "../../assets/images/hotel/bg02.jpg";
import hotelbg03 from "../../assets/images/hotel/bg03.jpg";
import team1 from "../../assets/images/construction/t1.jpg";
import team3 from "../../assets/images/construction/t3.jpg";
import team2 from "../../assets/images/construction/t2.jpg";
import team4 from "../../assets/images/construction/t4.jpg";
import news1 from "../../assets/images/construction/o1.jpg";
import new2 from "../../assets/images/construction/b1.jpg";
import news3 from "../../assets/images/construction/r1.jpg";
import course1 from "../../assets/images/course/1.jpg";
import course2 from "../../assets/images/course/2.jpg";
import course3 from "../../assets/images/course/3.jpg";
import course4 from "../../assets/images/course/4.jpg";
import course5 from "../../assets/images/course/5.jpg";
import course6 from "../../assets/images/course/6.jpg";
import course7 from "../../assets/images/course/7.jpg";
import course8 from "../../assets/images/course/8.jpg";
import course9 from "../../assets/images/course/9.jpg";

import team01 from "../../assets/images/client/01.jpg";
import team02 from "../../assets/images/client/02.jpg";
import team03 from "../../assets/images/client/03.jpg";
import team04 from "../../assets/images/client/04.jpg";

import personal01 from "../../assets/images/personal/1.jpg";
import personal02 from "../../assets/images/personal/2.jpg";
import personal03 from "../../assets/images/personal/3.jpg";
import ticket1 from "../../assets/images/icon/ticket1.svg";
import ticket2 from "../../assets/images/icon/ticket2.svg";
import ticket3 from "../../assets/images/icon/ticket3.svg";
const logisticsHomeData = [
  {
    id: 1,
    img: logistics01,
    titleLine1: "Logistic Solutions ",
    titleLine2: "Around the World",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
  {
    id: 2,
    img: logistics02,
    titleLine1: "We Make Your ",
    titleLine2: "Logistic Simple",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
  {
    id: 3,
    img: logistics03,
    titleLine1: "Flexible Logistics ",
    titleLine2: "& Cargo for Business",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
];

const partnersData = [
  {
    id: 1,
    img: amazon,
  },
  {
    id: 2,
    img: google,
  },
  {
    id: 3,
    img: lenovo,
  },
  {
    id: 4,
    img: paypal,
  },
  {
    id: 5,
    img: shopify,
  },
  {
    id: 6,
    img: spotify,
  },
];

const servicesData = [
  {
    id: 1,
    icon: "uil-truck",
    title: "Land Transport",
  },
  {
    id: 2,
    icon: "uil-plane-departure",
    title: "Air Freight",
  },
  {
    id: 3,
    icon: "uil-ship",
    title: "Ocean Freight",
  },
  {
    id: 4,
    icon: "uil-money-stack",
    title: "Cost Saving",
  },
  {
    id: 5,
    icon: "uil-package",
    title: "Safe Packing",
  },
  {
    id: 6,
    icon: "uil-fast-mail-alt",
    title: "Fast Shipping",
  },
];

const reviews = [
  {
    id: 1,
    img: img1,
    name: "F Stasi",
    post: "CEO & Founder",
    desc: "They have given us peace of mind so that we can focus on our customers and other things which matter most to our business. We have enjoyed our association with iAastha for several years, they are highly qualified and confident to handle all the key technology related matters efficiently.",
    rating: 5,
  },
  {
    id: 2,
    img: img2,
    name: "Vel M",
    post: "CEO & Founder",
    desc: "The Team at IAastha Research and Consulting did a good job building my platform. They are easy to communicate with and are equipped at dealing with the technical issues and concerns I have regarding the platform.",
    rating: 4.5,
  },
  {
    id: 3,
    img: img3,
    name: "Femi S",
    post: "CEO",
    desc: "iAastha developed our video calling platform, they applied innovative thinking and problem solving, what I appreciated most was their willingness to be flexible in the various iterations needed to get the work done. They work very hard and put a lot of effort into making a working product. Overall, their greatest strength is engineering work and they do this very well. They are experts in new technology and look for ways to make things simple while also guiding you regarding the best balance of technology deployment and business operations, a vital balance in any techn endeavor.",
    rating: 5,
  },
  {
    id: 4,
    img: img4,
    name: "R Sutaria",
    post: "Founder",
    desc: "Team worked on our data ingestion platform where they reduced the platform's ingestion time by 10X. The engagement of the team with our product has been good. We are satisfied with the work delivered",
    rating: 5,
  },
  {
    id: 5,
    img: img5,
    name: "S Kumar",
    post: "Phd",
    desc: "iAastha Team helped us in building a chatbot integration application from ground up for our product. All the requirements were examined carefully and the team was very innovative in designing and implementing the solution. We requested a few modifications that were taken care of and the team was very easy to work with. I am very happy with the end product and I highly recommend them.",
    rating: 5,
  },
];

const logisticsBlogs = [
  {
    id: 1,
    image: blog1,
    title: "Helping Companies in  Their Green Transition",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021",
  },
  {
    id: 2,
    image: blog2,
    title: "Key Logistics Trends in Life  Sciences 2021",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021",
  },
  {
    id: 3,
    image: blog3,
    title: "5 Benefits of Working with  a Freight Broker",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021",
  },
];

const featureArray = [
  {
    id: 1,
    icon: "uil uil-airplay h2 text-primary",
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper. ",
    imgUrl: user,
  },
  {
    id: 2,
    icon: "uil uil-calendar-alt h2 text-primary",
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
    imgUrl: calendar,
  },
  {
    id: 3,
    icon: "uil uil-clock h2 text-primary",
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
    imgUrl: sandClock,
  },
];

const testimonials = [
  {
    id: 1,
    name: "Thomas Israel",
    image: client1,
    desc: "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
  },
  {
    id: 2,
    name: "Carl Oliver",
    image: client2,
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 3,
    name: "Barbara McIntosh",
    image: client3,
    desc: "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
  },
  {
    id: 4,
    name: "Jill Webb",
    image: client4,
    desc: "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
  },
  {
    id: 5,
    name: "Dean Tolle",
    image: client5,
    desc: "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
  },
  {
    id: 6,
    name: "Christa Smith",
    image: client6,
    desc: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
  },
];

const pricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [{ title: "Full Access" }, { title: "Source Files" }],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 4,
    title: "ULTIMATE",
    price: 79,
    duration: "mo",
    buttonText: "Started Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Free Installment" },
    ],
  },
];

const facilities = [
  {
    icon: "shield",
    title: "Fully Secured",
    desc: "Moreover, in Latin only words at the beginning of sentences are capitalized.",
  },
  {
    icon: "cpu",
    title: "Best Performance",
    desc: "If the fill text is intended to illustrate the characteristics of sometimes.",
  },
];

const agencyBlogsData = [
  {
    id: 1,
    image: blog01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: blog02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: blog03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const agencyFeatureArray = [
  {
    id: 1,
    icon: "uil uil-edit-alt h2 text-primary",
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a eleifend Aliquam aenean elementum semper.",
    imgUrl: pen,
  },
  {
    id: 2,
    icon: "uil uil-vector-square h2 text-primary",
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text established by compiling unusual word.",
    imgUrl: video,
  },
  {
    id: 3,
    icon: "uil uil-file-search-alt h2 text-primary",
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
    imgUrl: intellectual,
  },
];

const appsFeatures = [
  {
    icon: "monitor",
    title: "Use On Any Device",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "feather",
    title: "Feather Icons",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "eye",
    title: "Retina Ready",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "user-check",
    title: "W3c Valid Code",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "smartphone",
    title: "Fully Responsive",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "heart",
    title: "Browser Compatibility",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
];

const appsPricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
    ],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
    ],
  },
];

const classicFeatures = [
  {
    id: 1,
    img: artDesign,
    title: "Design & Branding",
    desc: "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
  },
  {
    id: 2,
    img: smartphone,
    title: "Fully Secured",
    desc: "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
    isActive: true,
    isColorText: true,
    isDescColor: true,
  },
  {
    id: 3,
    img: clock,
    title: "High Performance",
    desc: "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
  },
];

const studioProcessesData = [
  {
    id: 1,
    title: "Discuss The Project",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
  {
    id: 2,
    title: "Develop & Elaborate",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
  {
    id: 3,
    title: "Final Approvement",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
];

const marketingCounters = [
  {
    title: "Happy Client",
    image: Asset190,
    start: 97,
    value: 97,
    postfix: "%",
  },
  { title: "Awards", image: Asset189, start: 15, value: 15, postfix: "+" },
  {
    title: "Job Placement",
    image: Asset192,
    start: 2,
    value: 2,
    postfix: "K",
  },
  {
    title: "Project Complete",
    image: Asset187,
    start: 98,
    value: 98,
    postfix: "%",
  },
];

const enterpriceFeatures = [
  {
    id: 1,
    icon: "uil uil-edit-alt",
    imgUrl: pen,
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: "uil uil-vector-square",
    imgUrl: video,
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: "uil uil-file-search-alt",
    imgUrl: intellectual,
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
  },
  {
    id: 4,
    icon: "uil uil-airplay",
    imgUrl: user,
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper",
  },
  {
    id: 5,
    icon: "uil uil-calendar-alt",
    imgUrl: calendar,
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 6,
    icon: "uil uil-clock h2",
    imgUrl: sandClock,
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const enterpricePricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [{ title: "Full Access" }, { title: "Source Files" }],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 4,
    title: "ULTIMATE",
    price: 79,
    duration: "mo",
    buttonText: "Started Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Free Installment" },
    ],
  },
];

const enterpriceBlogs = [
  {
    id: 1,
    image: blog01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: blog02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: blog03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const serviceFeatures = [
  {
    id: 1,
    icon: "uil uil-flip-h h2 text-primary",
    imgUrl: stairs,
    title: "Built for Everyone",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.",
  },
  {
    id: 2,
    icon: "uil uil-minus-path h2 text-primary",
    imgUrl: device,
    title: "Responsive Design",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 3,
    icon: "uil uil-layers-alt h2 text-primary",
    imgUrl: code,
    title: "Build Everything",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const serviceCounters = [
  {
    title: "Happy Client",
    end: 97,
    postFix: "%",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    title: "Awards",
    end: 15,
    postFix: "+",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    title: "Project Complete",
    end: 98,
    postFix: "%",
    desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
];

const paymentsProcess = [
  {
    id: 1,
    icon: "uil uil-rupee-sign h2 text-primary",
    title: "Low Fees",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted.",
  },
  {
    id: 2,
    icon: "uil uil-shield h2 text-primary",
    title: "Safe & Secure",
    desc: "For this reason, dummy text usually consists of a more or less random series of words.",
  },
  {
    id: 3,
    icon: "uil uil-headphones h2 text-primary",
    title: "Amazing Support",
    desc: "This prevents repetitive patterns from impairing the overall visual impression.",
  },
  {
    id: 4,
    icon: "uil uil-exchange h2 text-primary",
    title: "Convenient & Easy",
    desc: "It is advantageous when the dummy text is relatively realistic so that the layout.",
  },
];

const paymentsClientsData = [
  {
    id: 1,
    image: client1,
    name: "Thomas Israel",
    post: "C.E.O",
    rating: 5,
    desc: "It seems that only fragments of the oem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 2,
    image: client2,
    name: "Carl Oliver",
    post: "P.A",
    rating: 4,
    desc: "The advantage of its Latin origin andor distract the viewer's attention from the layout.",
  },
  {
    id: 3,
    image: client3,
    name: "Barbara McIntosh",
    post: "M.D",
    rating: 3.5,
    desc: "There is now an abundance of readableives to the classic and tell short, funny or nonsensical stories.",
  },
  {
    id: 4,
    image: client4,
    name: "Jill Webb",
    post: "Manager",
    rating: 4.5,
    desc: "According to most sources, Lorum Ipsuthe origin of the text by of the unusual word  he could find",
  },
  {
    id: 5,
    image: client5,
    name: " Dean Tolle",
    post: "Developer",
    rating: 5,
    desc: "It seems that only fragments of the oem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 6,
    image: client6,
    name: "Christa Smith",
    post: "Designer",
    rating: 5,
    desc: "It seems that only fragments of the otime certain letters at various positions within the text.",
  },
];

const itSolutionProgress = [
  {
    id: 1,
    img: Asset190,
    count: "97",
    percentage: "%",
    heading: "Happy Client",
  },
  {
    id: 2,
    img: Asset189,
    count: "15",
    percentage: "+",
    heading: "Awards",
  },
  {
    id: 3,
    img: Asset192,
    count: "2",
    percentage: "K",
    heading: "Job Placement",
  },
  {
    id: 4,
    img: Asset187,
    count: "98",
    percentage: "%",
    heading: "Project Complete",
  },
];

const itSolutionWhatwedo = [
  {
    id: 1,
    icon: "uil uil-chart-line",
    heading: "Hign Performance",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 2,
    icon: "uil uil-crosshairs",
    heading: "Best Securities",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 3,
    icon: "uil uil-airplay",
    heading: "Trusted Service",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 4,
    icon: "uil uil-rocket",
    heading: "Info Technology",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 5,
    icon: "uil uil-clock",
    heading: "24/7 Support",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 6,
    icon: "uil uil-users-alt",
    heading: "IT Management",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 7,
    icon: "uil uil-file-alt",
    heading: "Certified Company",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 8,
    icon: "uil uil-search",
    heading: "Data Analytics",
    desc: "It is a long established fact that a reader.",
  },
];

const developerKeyfeatures = [
  {
    id: 1,
    icon: "uil uil-layer-group d-block rounded h3 mb-0",
    hoverIcon: "uil uil-layer-group text-primary full-img",
    title: "Modular",
  },
  {
    id: 2,
    icon: "uil uil-airplay d-block rounded h3 mb-0",
    hoverIcon: "uil uil-airplay text-primary full-img",
    title: "Responsive",
  },
  {
    id: 3,
    icon: "uil uil-focus-target d-block rounded h3 mb-0",
    hoverIcon: "uil uil-focus-target text-primary full-img",
    title: "Customizable",
  },
  {
    id: 4,
    icon: "uil uil-expand-arrows d-block rounded h3 mb-0",
    hoverIcon: "uil uil-expand-arrows text-primary full-img",
    title: "Scalable",
  },
];

const businessFeatures = [
  {
    id: 1,
    icon: "uil uil-edit-alt h2 text-primary",
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: "uil uil-vector-square h2 text-primary",
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: "uil uil-file-search-alt h2 text-primary",
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
  },
  {
    id: 4,
    icon: "uil uil-airplay h2 text-primary",
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.",
  },
  {
    id: 5,
    icon: "uil uil-calendar-alt h2 text-primary",
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 6,
    icon: "uil uil-clock h2 text-primary",
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const hotelClientsData = [
  {
    id: 1,
    image: client1,
    name: "Thomas Israel",
    rating: 5,
    desc: "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 2,
    image: client2,
    name: "Carl Oliver",
    rating: 4,
    desc: "There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories..",
  },
  {
    id: 3,
    image: client3,
    name: "Barbara McIntosh",
    rating: 3.5,
    desc: "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 4,
    image: client4,
    name: "Jill Webb",
    rating: 4.5,
    desc: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word 'consectetur' he could find",
  },
  {
    id: 5,
    image: client5,
    name: " Dean Tolle",
    rating: 5,
    desc: "There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.",
  },
  {
    id: 6,
    image: client6,
    name: "Christa Smith",
    rating: 5,
    desc: "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.",
  },
];

const hotelRoomsData = [
  {
    imageUrl: hotel01,
    title: "Regular Room",
    bed: "1",
    bathrooms: "1",
    rent: "350",
    type: "Night",
  },
  {
    imageUrl: hotel02,
    title: "Regular Big Room",
    bed: "1",
    bathrooms: "1",
    rent: "800",
    type: "Night",
  },
  {
    imageUrl: hotel03,
    title: "Deluxe Room",
    bed: "1",
    bathrooms: "2",
    rent: "1100",
    type: "Night",
  },
  {
    imageUrl: hotel04,
    title: "Super Deluxe Room",
    bed: "2",
    bathrooms: "2",
    rent: "1400",
    type: "Night",
  },
  {
    imageUrl: hotel05,
    title: "Deluxe Big Room",
    bed: "3",
    bathrooms: "3",
    rent: "1600",
    type: "Night",
  },
  {
    imageUrl: hotel06,
    title: "Family Room",
    bed: "3",
    bathrooms: "2",
    rent: "1750",
    type: "Night",
  },
  {
    imageUrl: hotel07,
    title: "Premium Room",
    bed: "1",
    bathrooms: "1",
    rent: "1890",
    type: "Night",
  },
  {
    imageUrl: hotel08,
    title: "Super Premium Room",
    bed: "1",
    bathrooms: "1",
    rent: "2050",
    type: "Night",
  },
  {
    imageUrl: hotel09,
    title: "Premium Big Room",
    bed: "1",
    bathrooms: "1",
    rent: "2500",
    type: "Night",
  },
];

const hotelBlogsData = [
  {
    id: 1,
    image: hotelbg01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: hotelbg02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: hotelbg03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const hotelFeaturesData = [
  {
    id: 1,
    icon: "uil uil-wifi h2 text-primary",
    title: "Free WIFI",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: "uil uil-process h2 text-primary",
    title: "Relaxation",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: "uil uil-dumbbell h2 text-primary",
    title: "Spa & Fitness",
    description:
      "It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.",
  },
  {
    id: 4,
    icon: "uil uil-restaurant h2 text-primary",
    title: "Restaurant",
    description:
      "It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.",
  },
  {
    id: 5,
    icon: "uil uil-band-aid h2 text-primary",
    title: "Smooth Parallax",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 6,
    icon: "uil uil-bed-double h2 text-primary",
    title: "Bedrooms",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
];

const constructionMembers = [
  {
    id: 1,
    img: team1,
    name: "Ronny Jofra",
    position: "Architecture",
  },
  {
    id: 2,
    img: team3,
    name: "Micheal Carlo",
    position: "Project Manager",
  },
  {
    id: 3,
    img: team2,
    name: "Aliana Rosy",
    position: "Builder Advisor",
  },
  {
    id: 4,
    img: team4,
    name: "Sofia Razaq",
    position: "HR Manager",
  },
];

const constructionNewsData = [
  {
    id: 1,
    img: news1,
    title: "High quality work for demand our customer.",
  },
  {
    id: 2,
    img: new2,
    title: "Building public support for a severige work bond",
  },
  {
    id: 3,
    img: news3,
    title: "Satisfection for the customer our first parity.",
  },
];

const emailInboxFeatures = [
  {
    id: 1,
    icon: "uil uil-comment-verify d-block rounded h3 mb-0",
    title: "Instant Chat",
  },
  {
    id: 2,
    icon: "uil uil-image d-block rounded h3 mb-0",
    title: "Media Messages",
  },
  {
    id: 3,
    icon: "uil uil-video d-block rounded h3 mb-0",
    title: "Video Messages",
  },
  {
    id: 4,
    icon: "uil uil-database-alt d-block rounded h3 mb-0",
    title: "Dedicated Server",
  },
  {
    id: 5,
    icon: "uil uil-users-alt d-block rounded h3 mb-0",
    title: "Team Collaboration",
  },
  {
    id: 6,
    icon: "uil uil-apps d-block rounded h3 mb-0",
    title: "Responsive App",
  },
];

const travelBenefitData = [
  {
    id: 1,
    icon: "uil uil-shield-check",
    title: "Secure Payment",
  },
  {
    id: 1,
    icon: "uil uil-thumbs-up",
    title: "Easy Book",
  },
  {
    id: 1,
    icon: "uil uil-keyboard-show",
    title: "Free Amenities",
  },
  {
    id: 1,
    icon: "uil uil-award",
    title: "Best Offers",
  },
  {
    id: 1,
    icon: "uil uil-bookmark",
    title: "Cheap than Other",
  },
  {
    id: 1,
    icon: "uil uil-favorite",
    title: "Top Rated",
  },
  {
    id: 1,
    icon: "uil uil-clock",
    title: "24/7 Support",
  },
  {
    id: 1,
    icon: "uil uil-process",
    title: "Fast Refund",
  },
];

const coursesData = [
  {
    id: 1,
    image: course1,
    title: "Program for Missionaries",
    price: 75,
    rating: 5,
    person: 5,
    students: 30,
    lession: 5,
  },
  {
    id: 2,
    image: course2,
    title: "Access to Higher Education",
    price: 150,
    rating: 3.99,
    person: 5,
    students: 30,
    lession: 5,
    isStarClass: "star-outline",
  },
  {
    id: 3,
    image: course3,
    title: "Course in TEFL Teacher Training",
    price: 175,
    rating: 4.7,
    person: 5,
    students: 30,
    lession: 5,
    isStarClass: "star-half",
  },
  {
    id: 4,
    image: course4,
    title: "Educational Communication",
    price: 22,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 5,
    image: course5,
    title: "Introduction to Epidemiology",
    price: 100,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 6,
    image: course6,
    title: "Good Clinical Practice",
    price: 110,
    rating: 4,
    person: 2,
    students: 30,
    lession: 5,
    isStarClass: "star-outline",
  },
  {
    id: 7,
    image: course7,
    title: "Advanced Diagnostic Research",
    price: 149,
    rating: 4.5,
    person: 8,
    students: 30,
    lession: 5,
    isStarClass: "star-half",
  },
  {
    id: 8,
    image: course8,
    title: "Skills Development Facilitation",
    price: 135,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 9,
    image: course9,
    title: "PGCE Primary",
    price: 55,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
];

const candidatesData = [
  {
    id: 1,
    image: team01,
    name: "Ronny Jofra",
    designation: "Organizer",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 2,
    image: team04,
    name: "Micheal Carlo",
    designation: "Event Manager",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 3,
    image: team03,
    name: "Aliana Rosy",
    designation: "Motivator",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 4,
    image: team02,
    name: "Sofia Razaq",
    designation: "Speaker",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
];

const insuranceFeaturesData = [
  {
    icon: "uil uil-briefcase",
    title: "Our Vision",
    desc: "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
  {
    icon: "uil uil-rocket",
    title: "Our Mission",
    desc: "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
  {
    icon: "uil uil-crosshairs",
    title: "Our Goal",
    desc: "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
];

const personalBlogsData = [
  {
    id: 1,
    image: personal01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: personal02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: personal03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const eventPricingsData = [
  {
    id: 1,
    title: "1 DAY TICKETS",
    image: ticket1,
    price: "09",
    duration: "Day",
    desc: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  },
  {
    id: 2,
    title: "FULL TICKETS",
    image: ticket2,
    price: "24",
    duration: "Full",
    desc: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
    isActive: true,
  },
  {
    id: 3,
    title: "COUPLE TICKETS",
    image: ticket3,
    price: "16",
    duration: "Day",
    desc: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  },
];

const onepageFeaturesData = [
  {
    id: 1,
    icon: "uil uil-edit-alt h2 text-primary",
    title: "Data Engineering",
    description:
      "Data is new oil. Industry generates lot of data but dont know how to use it to gain insights. We mine the data and put insights in front of you.",
  },
  {
    id: 2,
    icon: "uil uil-vector-square h2 text-primary",
    title: "Security & Reliability",
    description:
      "We help enhance the security of your current system and ensure the services are always available for your customers.",
  },
  {
    id: 3,
    icon: "uil uil-file-search-alt h2 text-primary",
    title: "AI|Machine Learning|NLP",
    description:
      "We help you to identify likelihood of future outcomes using data, statistics, and machine learning techniques.",
  },
  {
    id: 4,
    icon: "uil uil-airplay h2 text-primary",
    title: "Research & Development",
    description:
      "We assist you in all the emerging technologies with our inhouse experts and researchers.",
  },
  {
    id: 5,
    icon: "uil uil-calendar-alt h2 text-primary",
    title: "Cloud Computing",
    description:
      "We use cloud services to enable fast development, create robust and scalable solutions for our customers.",
  },
  {
    id: 6,
    icon: "uil uil-clock h2 text-primary",
    title: "Communication & Availability",
    description:
      "Our team is always available and responsive to clients request. This ensures to keep high quality services delivery to our clients.",
  },
];

export {
  logisticsHomeData,
  partnersData,
  servicesData,
  reviews,
  logisticsBlogs,
  featureArray,
  testimonials,
  pricings,
  facilities,
  agencyBlogsData,
  agencyFeatureArray,
  appsFeatures,
  appsPricings,
  classicFeatures,
  studioProcessesData,
  marketingCounters,
  enterpriceFeatures,
  enterpricePricings,
  enterpriceBlogs,
  serviceFeatures,
  serviceCounters,
  paymentsProcess,
  paymentsClientsData,
  itSolutionProgress,
  itSolutionWhatwedo,
  developerKeyfeatures,
  businessFeatures,
  hotelClientsData,
  hotelRoomsData,
  hotelBlogsData,
  hotelFeaturesData,
  constructionMembers,
  constructionNewsData,
  emailInboxFeatures,
  travelBenefitData,
  coursesData,
  candidatesData,
  insuranceFeaturesData,
  personalBlogsData,
  eventPricingsData,
  onepageFeaturesData,
};
