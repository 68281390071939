/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input, Label } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import americanEx from "../../assets/images/payments/american-ex.png";
import discover from "../../assets/images/payments/discover.png";
import masterCard from "../../assets/images/payments/master-card.png";
import paypal from "../../assets/images/payments/paypal.png";
import visa from "../../assets/images/payments/visa.png";

//Import Images
import footerLogo from "../../assets/images/iaastha_white_logo.png";
import logoIaasthaPng from "../../assets/images/iaastha_white_logo.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grid1: [
        { title: "About", link: "/about" },
        // { title: "Services", link: "/page-services" },
        // { title: "Team", link: "/page-team" },
        // { title: 'Pricing', link: '/page-pricing' },
        // { title: 'Project', link: '#' },
        // { title: "Careers", link: "/index-job" },
        { title: "R&D", link: "/research" },
        { title: "Case Studies", link: "/all-cases" },
        // { title: 'Login', link: '#' },
      ],
      grid2: [
        { title: "Terms of Services", link: "#" },
        { title: "Privacy Policy", link: "#" },
        { title: "Documentation", link: "#" },
        { title: "Changelog", link: "#" },
        { title: "Components", link: "#" },
      ],
      paymentCardData: [
        {
          img: americanEx,
          title: "American Express",
        },
        {
          img: discover,
          title: "Discover",
        },
        {
          img: masterCard,
          title: "Master Card",
        },
        {
          img: paypal,
          title: "Paypal",
        },
        {
          img: visa,
          title: "Visa",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col className="col-12">
                <div
                  className={
                    this.props.isBorderLine
                      ? "footer-py-60 footer-border"
                      : "footer-py-60"
                  }
                >
                  <Row>
                    <Col lg={6} className="col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                      <Link to="#" className="logo-footer">
                        <img
                          src={this.props.isLight ? logoIaasthaPng : footerLogo}
                          height="30"
                          alt=""
                        />
                      </Link>
                      <p
                        className={
                          this.props.isLight ? "mt-4 text-muted" : "mt-4"
                        }
                      >
                        Igniting startups with tech brilliance! Upscale Your
                        Ideas with Us.
                      </p>
                      <ul
                        className={
                          this.props.isLight
                            ? "list-unstyled social-icon social mb-0 m t-4"
                            : "list-unstyled social-icon foot-social-icon mb-0 mt-4"
                        }
                      >
                        <li className="list-inline-item me-1">
                          <Link
                            to="https://www.facebook.com/iAasthaTechnologies/"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        {/* <li className="list-inline-item me-1">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li> */}
                        <li className="list-inline-item me-1">
                          <Link
                            to="https://twitter.com/iaastha2"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item me-1">
                          <Link
                            to="https://www.linkedin.com/company/iaastha-technologies/"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                      </ul>
                    </Col>

                    <Col
                      lg={3}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head"
                            : "text-light footer-head  pb-3"
                        }
                      >
                        Company
                      </h5>
                      <ul className="list-unstyled footer-list">
                        {this.state.grid1.map((grid, key) => (
                          <li key={key}>
                            <Link
                              to={grid.link}
                              className={
                                this.props.isLight ? "text-muted" : "text-foot"
                              }
                            >
                              <i className="uil uil-angle-right-b me-1"></i>{" "}
                              {grid.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col
                      lg={3}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head pb-3"
                            : "text-light footer-head pb-3"
                        }
                      >
                        ©2024, IAastha Research and Consulting.
                      </h5>
                      {/* <p className="mt-4">
                        +91-887-880-7184
                        <br />
                        it@iaastha.com | ashish@iaastha.com
                      </p> */}
                      <ul className="list-unstyled footer-list">
                        <li>+91-887-880-7184</li>
                        <li>it@iaastha.com | ashish@iaastha.com</li>
                      </ul>
                      <Form>
                        <Row>
                          <Col lg={12}>
                            <div
                              className={
                                this.props.isLight
                                  ? "foot-subscribe mb-3 foot-white"
                                  : "foot-subscribe mb-3"
                              }
                            >
                              <div className="form-icon position-relative pt-2">
                                <Link
                                  to="/terms-and-conditions"
                                  className={
                                    this.props.isLight
                                      ? "text-muted"
                                      : "text-foot"
                                  }
                                >
                                  Terms And Conditions
                                </Link>
                              </div>
                              <div className="form-icon position-relative pt-2">
                                <Link
                                  to="https://iaastha.com/sitemap.xml"
                                  className={
                                    this.props.isLight
                                      ? "text-muted"
                                      : "text-foot"
                                  }
                                >
                                  SiteMap
                                </Link>
                              </div>
                              <div className="d-grid pt-2">
                                <Link
                                  to="/privacy"
                                  className={
                                    this.props.isLight
                                      ? "text-muted"
                                      : "text-foot"
                                  }
                                >
                                  Privacy Policy
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <div className="footer-py-30 footer-bar">
            <Container className="text-center">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="text-sm-start">
                    <p className="mb-0">© {(new Date().getFullYear())} Landrick. Design with {" "}
                      <i className="mdi mdi-heart text-danger"></i> by {" "}
                      <Link
                        to="https://themesbrand.in/"
                        target="_blank"
                        className="text-reset"
                        rel="noopener noreferrer"
                      >
                        Themesbrand
                      </Link>.</p>
                  </div>
                </Col>

                <Col sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled text-sm-end mb-0 d-flex gap-1 flex-wrap justify-content-sm-end">
                    {this.state.paymentCardData.map((item, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to="#">
                          <img
                            src={item.img}
                            className="avatar avatar-ex-sm"
                            title={item.title}
                            alt=""
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </div> */}
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
