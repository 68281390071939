import React, { Component, Suspense } from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Row, Form, Label, Input } from 'reactstrap';

// import images
import americanEx from '../../../assets/images/payments/american-ex.png';
import discover from '../../../assets/images/payments/discover.png';
import masterCard from '../../../assets/images/payments/master-card.png';
import paypal from '../../../assets/images/payments/paypal.png';
import visa from '../../../assets/images/payments/visa.png';

//Import Images
import logodark from '../../../assets/images/logo-dark.png';

// import footerLogo from '../../../assets/images/footerLogo.png';
import logoIaasthaPng from '../../../assets/images/logoIaasthaPng.png';
//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Switcher
import BackToTop from "../../../components/Layout/backToTop";

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Footer6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grid1: [
        { title: 'About us', link: '/page-aboutus-two' },
        { title: 'Services', link: '/page-services' },
        { title: 'Team', link: '/page-team' },
        // { title: 'Pricing', link: '/page-pricing' },
        // { title: 'Project', link: '#' },
        { title: 'Careers', link: '/index-job' },
        { title: 'Blog', link: 'https://medium.com/iaastha' },
        // { title: 'Login', link: '#' },
      ],
      grid2: [
        { title: 'Terms of Services', link: '#' },
        { title: 'Privacy Policy', link: '#' },
        { title: 'Documentation', link: '#' },
        { title: 'Changelog', link: '#' },
        { title: 'Components', link: '#' },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <footer className="footer-light footer">
            <Container>
              <Row>
                <Col xs="12">
                  <div className="footer-py-60">
                    <Row>
                      <Col lg={6} xs={12} className="mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link to="#" className="logo-footer">
                          <img src={logoIaasthaPng} height="24" alt="" />
                        </Link>
                        <p className="mt-4">
                          Start working with Landrick that can provide everything
                          you need to generate awareness, drive traffic, connect.
                        </p>
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                          <li className="list-inline-item">
                            <Link to="https://www.facebook.com/iAasthaTechnologies/" className="rounded me-1">
                              <FeatherIcon
                                icon="facebook"
                                className="fea icon-sm fea-social"
                              />
                            </Link>
                          </li>
                          {/* <li className="list-inline-item">
                            <Link to="#" className="rounded me-1">
                              <FeatherIcon
                                icon="instagram"
                                className="fea icon-sm fea-social"
                              />
                            </Link>
                          </li> */}
                          <li className="list-inline-item">
                            <Link to="https://twitter.com/iaastha2" className="rounded me-1">
                              <FeatherIcon
                                icon="twitter"
                                className="fea icon-sm fea-social"
                              />
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link to="https://www.linkedin.com/company/iaastha-technologies/" className="rounded me-1">
                              <FeatherIcon
                                icon="linkedin"
                                className="fea icon-sm fea-social"
                              />
                            </Link>
                          </li>
                        </ul>
                      </Col>

                      <Col
                        lg={3} md={4}
                        className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                        name="footercolumn"
                      >
                        <h5 className="footer-head">Company</h5>
                        <ul className="list-unstyled footer-list mt-4">
                          {this.state.grid1.map((grid, key) => (
                            <li key={key}>
                              <Link
                                to={grid.link}
                                className={
                                  this.props.isLight ? 'text-muted' : 'text-foot'
                                }
                              >
                                <i className="uil uil-angle-right-b me-1"></i>{' '}
                                {grid.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Col>



                      <Col
                        lg={3} md={4}
                        xs="12"
                        className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                        name="footercolumn"
                      >
                        <h5 className="footer-head">©2023, IAastha Research and Consulting.</h5>
                        <p className="mt-4">+91-887-880-7184<br />
                          it@iaastha.com | ashish@iaastha.com</p>
                        <Form>
                          <Row>
                            <Col lg="12">
                              <div
                                className={
                                  this.props.isLight
                                    ? 'foot-subscribe mb-3 foot-white'
                                    : 'foot-subscribe mb-3'
                                }
                              >
                                <Link
                                  to='https://iaastha.com/terms%20of%20service/'
                                  className={
                                    this.props.isLight ? 'text-muted' : 'text-foot'
                                  }
                                >

                                  Terms And Conditions
                                </Link>
                                <div className="form-icon position-relative">
                                  <Link
                                    to='https://iaastha.com/sitemap.xml'
                                    className={
                                      this.props.isLight ? 'text-muted' : 'text-foot'
                                    }
                                  >

                                    SiteMap
                                  </Link>


                                </div>
                                <div className="d-grid">
                                  <Link
                                    to='https://iaastha.com/privacy/'
                                    className={
                                      this.props.isLight ? 'text-muted' : 'text-foot'
                                    }
                                  >

                                    Privacy Policy
                                  </Link>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>

           
          </footer>

          <BackToTop />
          {/* theme switcher */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Footer6;
