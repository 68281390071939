import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export default class WorkProcess extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                {/*<h6 className="text-primary">Work Process</h6>*/}
                <h4 className="title mb-4">Approach & Thinking</h4>
                {/*<p className="text-muted para-desc mx-auto mb-0">*/}
                {/*  Start working with{" "}*/}
                {/*  <span className="text-primary fw-bold">*/}
                {/*    Landrick*/}
                {/*  </span>{" "}*/}
                {/*  that can provide everything you need to generate awareness,*/}
                {/*  drive traffic, connect.*/}
                {/*</p>*/}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Innovative</h5>
                  <p className="text-muted mb-0">
                  At the heart of our approach is the ability to think outside the box, filter out noise, and fearlessly embrace the unknown. Our team embodies a ‘YES’ attitude, eager to tackle your ideas and challenges.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Creative</h5>
                  <p className="text-muted mb-0">
                    With a proactive mindset, our team delves into understanding the true needs of our clients. Our skilled members bring creative ideas to life with smart and simple engineering solutions.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Simplicity</h5>
                  <p className="text-muted mb-0">
                    We believe complex problems often have simple solutions. Our focus is on smartly breaking down challenges into manageable parts, solving big problems in small, efficient steps.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
