import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

//import images
import work1 from "../../../assets/images/work/1.jpg";
import work2 from "../../../assets/images/work/3.jpg";
import Footer4 from '../PageFooterLayouts/Footer4';
import ThemeSwitcher from '../../../components/Layout/ThemeSwitcher';

//import Components
import NavBar from "./Portfolio/NavBar";

class PageWorkDetailFour extends Component {
    render() {
        return (
            <React.Fragment>
            <NavBar />
                <section className="bg-half-170 bg-light d-table w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title"> Service Details </h4>
                                    <ul className="list-unstyled mt-4 mb-0">
                                        <li className="list-inline-item h6 user text-muted me-2"> Service Name</li>{" "}
                                        {/* <li className="list-inline-item h6 date text-muted"> <span className="text-dark">Date :</span> 23th Sep, 2021</li> */}
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb bg-white rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">IAastha</Link></li>{" "}
                                    <li className="breadcrumb-item"><Link to="#">Service</Link></li>{" "}
                                    <li className="breadcrumb-item"><Link to="#">Detail</Link></li>{" "}
                                    {/* <li className="breadcrumb-item active" aria-current="page">Work Detail</li> */}
                                </ul>
                            </nav>
                        </div>
                    </Container>
                </section>

                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <section className="section">
                    <Container>
                        <Row>
                            <Col lg={12} md={6} className="text-center mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                                <div className="work-detail">
                                    <h4 className="title mb-3">Service Overview  :</h4>
                                    <p className="description text-muted">Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
                                    <p className="description text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area). For this reason, dummy text usually consists of a more or less random series of words or syllables.</p>

                                    <Row>
                                        <Col lg={6} className="mt-4 pt-2">
                                            <img src={work1} className="img-fluid rounded" alt="" />
                                        </Col>

                                        <Col lg={6} className="mt-4 pt-2">
                                            <img src={work2} className="img-fluid rounded" alt="" />
                                        </Col>
                                    </Row>

                                    {/* <blockquote className="blockquote text-center mt-4 mb-0 p-md-4">
                                        <span className="h3 text-muted"><i className="mdi mdi-format-quote-open"></i></span>
                                        <p className="text-muted mb-0 h5 fw-normal fst-italic"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                        <span className="h3 text-muted"><i className="mdi mdi-format-quote-open"></i></span>
                                    </blockquote> */}
                                    <p className="description text-muted mt-4 mt-sm-0 mb-0">If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area). For this reason, dummy text usually consists of a more or less random series of words or syllables.</p>
                                </div>
                            </Col>

                            
                        </Row>
                    </Container>

                    <Container className="mt-100 mt-60">
                        <Row className="justify-content-center">
                            <Col xs={12} className="text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-3">Other Services</h4>
                                    <p className="text-muted mx-auto para-desc mb-0">Obviously I'm a Web Designer. Experienced with all stages of the development cycle for dynamic web projects.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <Card className="blog rounded border-0 shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img src={work1} className="card-img-top" alt="..." />
                                        <div className="overlay rounded-top bg-dark"></div>
                                    </div>
                                    <CardBody className="content">
                                        <h5><Link to="portfolio-detail-two" className="card-title title text-dark">Smartest Applications for Business</Link></h5>
                                        <div className="post-meta d-flex justify-content-between mt-3">
                                            {/* <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="uil uil-heart me-1"></i>33</Link></li>{" "}
                                                <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="uil uil-comment me-1"></i>08</Link></li>
                                            </ul> */}
                                            <Link to="/portfolio-detail-two" className="text-muted readmore">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                        </div>
                                    </CardBody>
                                    {/* <div className="author">
                                        <small className="text-light user d-block"><i className="uil uil-user"></i> Calvin Carlo</small>
                                        <small className="text-light date"><i className="uil uil-calendar-alt"></i> 25th June 2021</small>
                                    </div> */}
                                </Card>
                            </Col>

                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="card blog rounded border-0 shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img src={work2} className="card-img-top" alt="..." />
                                        <div className="overlay rounded-top bg-dark"></div>
                                    </div>
                                    <CardBody className="content">
                                        <h5><Link to="#" className="card-title title text-dark">Design your apps in your own way</Link></h5>
                                        <div className="post-meta d-flex justify-content-between mt-3">
                                            {/* <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="uil uil-heart me-1"></i>33</Link></li>{" "}
                                                <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="uil uil-comment me-1"></i>08</Link></li>
                                            </ul> */}
                                            <Link to="/page-blog-detail" className="text-muted readmore">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                        </div>
                                    </CardBody>
                                    {/* <div className="author">
                                        <small className="text-light user d-block"><i className="uil uil-user"></i> Calvin Carlo</small>
                                        <small className="text-light date"><i className="uil uil-calendar-alt"></i> 25th June 2021</small>
                                    </div> */}
                                </div>
                            </Col>

                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="card blog rounded border-0 shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img src={work1} className="card-img-top" alt="..." />
                                        <div className="overlay rounded-top bg-dark"></div>
                                    </div>
                                    <CardBody className="content">
                                        <h5><Link to="#" className="card-title title text-dark">Smartest Applications for Business</Link></h5>
                                        <div className="post-meta d-flex justify-content-between mt-3">
                                            {/* <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="uil uil-heart me-1"></i>33</Link></li>{" "}
                                                <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="uil uil-comment me-1"></i>08</Link></li>
                                            </ul> */}
                                            <Link to="/page-blog-detail" className="text-muted readmore">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                        </div>
                                    </CardBody>
                                    {/* <div className="author">
                                        <small className="text-light user d-block"><i className="uil uil-user"></i> Calvin Carlo</small>
                                        <small className="text-light date"><i className="uil uil-calendar-alt"></i> 25th June 2021</small>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer4 />
                <ThemeSwitcher/>
            </React.Fragment>
        );
    }
}

export default PageWorkDetailFour;